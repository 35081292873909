import Header from '../../components/Header';  
import Footer from '../../components/Footer';
function HumanResources() { 
	return( 
	<>  
    
      <Header /> 
      <div class="breadcrumb-area bg-gray bg-cover">
        <div class="container">
            <div class="breadcrumb-item">
                <div class="breadcrum-shape">
                    <img src="./assets/images/bg-shape-3.png" alt="shape" />
                </div>
                <div class="row">
                    <div class="col-lg-8">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li><a href="/Careers"><i class="fa fa-home"></i>Careers</a></li>
                                <li class="active">Job Description</li>
                            </ol>
                        </nav>
                        <h1>Job Description</h1>
                    </div>
                </div>
            </div>
        </div>
      </div>

      <article role="article" class="node node--type-kenexa-jobs node--view-mode-full clearfix" id="training-hiring-programs" >
        <div class="section-jobs-page">
            <div class="container">
            {/* <div class="page-title">
                <h2>Job Description</h2>
            </div> */}
            <div class="node__content clearfix">
                <h3 class="node__title jobs-title custom-text-uppercase">
                <span class="field field--name-title field--type-string field--label-hidden">Human Resources </span>
                </h3>
                <div class="main-content-body">
                <div class="left-info">
                    <h4 class="job-summary">Job Summary</h4>
                    <div class="row">
                    <div class="col-md-4 kenexa-inline-field jobs-location">
                        <div class="kenexa-inline-box"> 
                        <div class="kenexa-field-text">
                            <h6 class="custom-text-uppercase">Location:</h6>
                            <p> Bangalore </p>
                        </div>
                        </div>
                    </div>
                    <div class="col-md-4 kenexa-inline-field jobs-designation">
                        <div class="kenexa-inline-box"> 
                        <div class="kenexa-field-text">
                            <h6 class="custom-text-uppercase">Designation:</h6>
                            <p>Human Resources Manager</p>
                        </div>
                        </div>
                    </div>
                    <div class="col-md-4 kenexa-inline-field jobs-qualification">
                        <div class="kenexa-inline-box"> 
                        <div class="kenexa-field-text">
                            <div class="qualification">
                            <h6 class="custom-text-uppercase">Qualification:</h6>
                            </div>
                            <p>B.E, B.Tech </p>
                        </div>
                        </div>
                    </div>
                    <div class="col-md-4 kenexa-inline-field jobs-experience">
                        <div class="kenexa-inline-box"> 
                        <div class="kenexa-field-text">
                            <h6 class="custom-text-uppercase">Experience:</h6>
                            <p>2.5-3 Years </p>
                        </div>
                        </div>
                    </div>
                    <div class="col-md-4 kenexa-inline-field jobs-skills">
                        <div class="kenexa-inline-box"> 
                        <div class="kenexa-field-text">
                            <div class="skills">
                            <h6 class="custom-text-uppercase">Skills:</h6>
                            </div>
                            <p>Talent & Resources Management</p>
                        </div>
                        </div>
                    </div>
                    <div class="col-md-4 kenexa-inline-field jobs-positions">
                        <div class="kenexa-inline-box"> 
                        <div class="kenexa-field-text">
                            <h6 class="custom-text-uppercase">No. of positions:</h6>
                            <p> 02 </p>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 jobs-description">
                    <div class="kenexa-inline-box d-block">
                        <div class="kenexa-inline-box-job d-flex"> 
                        <h6 class="custom-text-uppercase">Job description:</h6>
                        </div>
                        <div class="kenexa-field-text">
                        <div class="jobs-description">
                            <div class="description">
                            {/* <p> Role & responsibilities</p> */}
                            <ul>
                                <li>Bachelor’s degree or equivalent work experience in Human Resources, Organizational Development, Labor Relations, or Business.</li>
                                <li>Have a clear understanding of your employer's business objectives and be able to devise and implement policies that support them.</li>
                                <li>Proficiency in Microsoft Office applications and Human Resources Information Systems (HRIS).</li>
                                <li>Knowledge of compensation strategy, performance management, employee relations, safety practices and talent acquisition, and the ability to apply this information in a manner that is compliant with relevant employment law.</li>
                                <li>Manage the application process - check application forms, shortlist, interview, select successful candidate/s and inform unsuccessful ones.</li>
                                <li>Advise on pay and other benefits, undertake salary reviews and manage redundancy programmes.</li>
                                <li>Carry out strategy and planning activities and develop strategies to meet immediate and long-term staff requirements.</li>
                                <li>Develop and implement policies on issues such as working conditions, performance management, equal opportunities, disciplinary procedures, and absence management.</li>
                                <li>Negotiate with staff and their representatives (e.g., trade union officials) on issues relating to pay and conditions, contracts, and redundancy.</li>
                                <li>Prepare staff handbooks, administer payroll, and maintain employee records.</li>
                                <li>Interpret and advise on employment law.</li>
                                <li>Deal with grievances and implement disciplinary procedures.</li>


                            </ul>
                            <p></p>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div class="col-lg-12">
                    <div class="jobs-link mt-3">
                        <a class="long-button blue" href="/JobApply" rel="noopener">Apply</a>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </article>
      
      <Footer/>

  </> 
  ); 
  } 
  export default HumanResources;