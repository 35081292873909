import Header from '../../components/Header';  
import Footer from '../../components/Footer';
import LatestNews from '../Home/LatestNews';
import WorkWithUs from '../Home/WorkWithUs';
import GroupWebsite from '../Home/GroupWebsite';
function Businesses() { 
	return( 
	<>  
    
      <Header /> 
      <div class="breadcrumb-area bg-gray bg-cover">
        <div class="container">
            <div class="breadcrumb-item">
                <div class="breadcrum-shape">
                    <img src="./assets/images/bg-shape-3.png" alt="shape" />
                </div>
                <div class="row">
                    <div class="col-lg-8">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li><a href="/"><i class="fa fa-home"></i> Home</a></li>
                                <li class="active">Businesses</li>
                            </ol>
                        </nav>
                        <h1>Businesses</h1>
                    </div>
                </div>
            </div>
        </div>
      </div>


      <section class="section business_tabs">
         <div class="container">                       
            <div class="section-header pb-lg-4 text-center">
               <h2 class="heading font-regular" data-aos="fade-up" data-aos-offset="100" data-aos-duration="650" data-aos-easing="ease-in-out">Business Overview</h2>
               <div class="only-value-content-counter">
                  <p class="width-70" data-aos="fade-up" data-aos-offset="100" data-aos-duration="650" data-aos-easing="ease-in-out">
                  Pixalive businesses consist of both product-based and service-based offerings
                  </p>
               </div>
            </div>
         </div>
         <div class="container">
            <div class="row">
               <ul class="nav nav-tabs">
                  <li class="active">
                  <a href="#PixaliveEnterprises" data-toggle="tab" class="active">Pixalive Platforms</a>
                  </li>
                  <li>
                  <a href="#PixaliveGreen" data-toggle="tab" class="">Pixalive Works</a>
                  </li>
                  <li>
                  <a href="#PixalivePorts" data-toggle="tab" class="">Pixalive Ventures</a>
                  </li>
                  <li>
                  <a href="#PixaliveTransmission" data-toggle="tab" class="">Pixalive Consultancy Services</a>
                  </li>
                  <li>
                  <a href="#PixaliveGas" data-toggle="tab" class="">Pixalive Labs</a>
                  </li>
                  <li>
                  <a href="#Pixalivepower" data-toggle="tab" class="">Pixalive Foundation</a>
                  </li> 
               </ul>
               <div class="tab-content pt-0">
                  <div class="tab-pane active" id="PixaliveEnterprises">
                     <div class="panel panel-default">
                        <div  class="panel-collapse collapse in show">
                           <div class="panel-body">
                              <div class="row">                              
                                 <div class="col-md-12 col-lg-12 ">
                                    <div class="tab-panel-heading">
                                       <h4>Pixalive Platforms</h4>
                                       <h6>Connecting Minds, Empowering Businesses: Your Privacy-Centric Network.</h6> 
                                    </div>
                                 </div>
                                 <div class="col-md-6 col-lg-6 my-3 all business">
                                    <div class="business-main">
                                       <a href="/businesses/solar-manufacturing" class="business-tile">
                                          <div class="business-thumb">
                                             <img src="../assets/images/social.jpg" alt="" />
                                          </div>
                                          <div class="business-title">
                                             <h5>Pixalive</h5>
                                             <p>Pixalive stands as India's pioneering interest-based social media networking application, offering rewards to all users on its platform. It empowers regular users to generate earnings through their daily activities and engagement within the app. Earn Pixalive Rewards by uploading videos, watching content, and inviting friends to join the community. Maximize your benefits by engaging in these activities more frequently! This marks the first instance in India where a social media application values and motivates users through rewards. </p>
                                             <h6>Significance & how it differs from Western Social Media</h6>
                                             <p>Pixalive is interest based social media application, where you can connect with like-minded people and spend your valuable time on your chosen category and content.</p>
                                             <p>Unlike other platform where they value only Celebrities, Influencers and Creators and left users aside. Whereas Pixalive values common users along with others based on their activity in our platform. Also sets a platform to earn money based on their activities in our application.</p>
                                             <p>Common users can also become Creators & Influencers on our platform based on their follower’s count. </p>
                                          </div>
                                       </a> 
                                    </div>
                                 </div>
                                 <div class="col-md-6 col-lg-6 my-3 all business">
                                    <div class="business-main">
                                       <a href="/businesses/solar-manufacturing" class="business-tile">
                                          <div class="business-thumb">
                                             <img src="../assets/images/weblive.jpeg" alt="" />
                                          </div>
                                          <div class="business-title">
                                             <h5>Webalive</h5>
                                             <p>It's a business application platform where business users can post their products and links to their Websites, Play Store, and App Store applications in order to promote their products. Ordinary users on our platform share these links & products with their connections, friends, and networks on social media sites and WhatsApp, and in return, they can earn money. This not only helps businesses gain traction but also enables them to reach a wider audience and increase lead conversions. </p>
                                             <h6>Significance & how it differs from Western Social Media</h6>
                                             <p>Many vendors and MSME sectors do not have their own websites; they have been conducting their businesses offline for years. However, they face challenges when it comes to online marketing and expanding their market. They often rely on Western social media platforms, and without a website, they struggle to promote and sell their products.</p>
                                             <p>In our platform, we have introduced a cart option that allows them to list their products and conduct business without the need for a website. </p>
                                             <p>If they do want a website for their business, Pixalive can create one through its PCS division at an affordable cost for the vendors.</p>
                                          </div>
                                       </a> 
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="tab-pane" id="PixaliveGreen">
                     <div class="panel panel-default"> 
                        <div class="panel-collapse collapse">
                           <div class="panel-body">
                              <div class="row"> 
                                 <div class="col-lg-6 about-flex">
                                    <div class="business-title">
                                       <h5>Pixalive Works</h5>
                                       <h6>Seamless Solutions, Limitless Possibilities: Your SaaS Partner</h6>
                                       <p>Pixalive Works is a part of Pixalive Ventures that specializes in offering software solutions through a SaaS model.</p>
                                       <p>SaaS stands for Software as a Service, which means they provide software applications to customers over the internet.</p>
                                    </div>
                                 </div>
                                 <div class="col-lg-6 about-flex">
                                    <img src="assets/images/pix-works.jpg" alt="Image Not Found" />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="tab-pane" id="PixalivePorts">
                  <div class="panel panel-default"> 
                     <div  class="panel-collapse collapse">
                        <div class="panel-body">
                           <div class="row"> 
                              <div class="col-lg-6 about-flex">
                                 <img src="assets/images/pix-ventures.jpg" alt="Image Not Found" />
                              </div>
                              <div class="col-lg-6 about-flex">
                                 <div class="business-title">
                                    <h5>Pixalive Ventures</h5>
                                    <h6>From Ideas to Success: Your All-in-One Support Partner.</h6>
                                    <p>Pixalive Ventures is a technology-focused firm deeply committed to startups. Our mission is to empower visionary entrepreneurs by offering a holistic range of support. From essential guidance and cutting-edge technology solutions to strategic marketing, robust resource support, expertise in scaling businesses, adept legal and compliance assistance, and facilitation of funding. </p>
                                    <p>At Pixalive Ventures, we are passionate about supporting startups and driving innovation. We are committed to being your trusted partner on your path to success. </p>
                                    <p>Our vision is to build long-term partnerships with startups, helping them turn their ideas into successful businesses. </p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  </div>
                  <div class="tab-pane" id="PixaliveTransmission">
                  <div class="panel panel-default"> 
                     <div  class="panel-collapse collapse">
                        <div class="panel-body">
                           <div class="row"> 
                              <div class="col-lg-6 about-flex">
                                 <div class="business-title">
                                    <h5>Pixalive Consultancy Services</h5>
                                    <h6>Unlocking IT Excellence, One Platform, Many Solutions.</h6>
                                    <p>We excel in offering a comprehensive range of IT services, digital services, consulting, business solutions, project management, and talent outsourcing all within a single platform, making us a leader in the Indian market.</p>
                                    <p>Pixalive Consultancy Services is India's premier resource and project management platform. Our platform facilitates connections between companies and top talent from around the world while also assisting companies in acquiring projects and sharing resources.</p>
                                 </div>
                              </div>
                              <div class="col-lg-6 about-flex">
                                 <img src="assets/images/pix-services.jpg" alt="Image Not Found" />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  </div>
                  <div class="tab-pane" id="PixaliveGas">
                     <div class="panel panel-default"> 
                        <div class="panel-collapse collapse">
                           <div class="panel-body">
                              <div class="row"> 
                                 <div class="col-lg-6 about-flex">
                                    <img src="assets/images/pix-labs.jpg" alt="Image Not Found" />
                                 </div>
                                 <div class="col-lg-6 about-flex">
                                    <div class="business-title">
                                       <h5>Pixalive Labs</h5>
                                       <h6>Embrace a big future with us!</h6>
                                       <p>We believe that a strong workforce is key to driving innovation and success. In our Pixalive Labs division, we focus on corporate training and skill development. We offer a range of technology programs designed to enhance the capabilities of individuals and teams, equipping them with the necessary skills to thrive in the ever-evolving business landscape.</p>
                                       <p>Additionally, through our 'Employee Development Program,' we extend the opportunity to train our clients' organizational employees, helping them excel in their chosen tech stacks. We invite you to 'Train Today and be ready for tomorrow.</p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="tab-pane" id="Pixalivepower">
                     <div class="panel panel-default"> 
                        <div  class="panel-collapse collapse">
                           <div class="panel-body">
                           <div class="row">                              
                                 <div class="col-md-12 col-lg-12 ">
                                    <div class="tab-panel-heading">
                                       <h4>Pixalive Foundation</h4>
                                       <h6>Enriching Lives, Nurturing the Future: Sustainable Impact with Pixalive</h6> 
                                       <p>Pixalive Foundation is the Corporate Social Responsibility (CSR) activity division of Pixalive Group. Focuses sustainability in Environment & Education.</p>
                                       <p>To empower individuals and nations through innovative technology solutions that enhance education, promote sustainable living, and foster global connectivity, ultimately creating a brighter and more inclusive future for all.</p>
                                    </div>
                                 </div>
                                 <div class="col-md-6 col-lg-6 my-3 all business">
                                    <div class="business-main">
                                       <a href="/businesses/solar-manufacturing" class="business-tile">
                                          <div class="business-thumb">
                                             <img src="../assets/images/environment.png" alt="" />
                                          </div>
                                          <div class="business-title">
                                             <h5>Environment</h5>
                                             <h6>Planting Trees, Reducing Emissions, Saving Tomorrow</h6>
                                             <p>Protecting the environment is our responsibility, especially in a world with rapid carbon emissions.</p>
                                             <p>While we may not completely stop emissions, we can reduce them by planting and caring for trees.</p> 
                                          </div>
                                       </a> 
                                    </div>
                                 </div>
                                 <div class="col-md-6 col-lg-6 my-3 all business">
                                    <div class="business-main">
                                       <a href="/businesses/solar-manufacturing" class="business-tile">
                                          <div class="business-thumb">
                                             <img src="../assets/images/education.png" alt="" />
                                          </div>
                                          <div class="business-title">
                                             <h5>Education</h5>
                                             <h6>Empowering Rural Women, Coding Dreams, Changing Lives</h6>
                                             <p>Pixalive is empowering rural women through free programming training, giving them job opportunities, and helping them achieve their dreams.</p>
                                          </div>
                                       </a> 
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  
               </div>
            </div>
         </div>
      </section>

      <LatestNews/> 
    <GroupWebsite/> 
    <WorkWithUs/> 
      
      <Footer/>

  </> 
  ); 
  } 
  export default Businesses;