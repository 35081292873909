import Header from '../../components/Header';  
import Footer from '../../components/Footer';
function Sustainability() { 
	return( 
	<>  
    
      <Header /> 
      <div class="breadcrumb-area bg-gray bg-cover">
        <div class="container">
            <div class="breadcrumb-item">
                <div class="breadcrum-shape">
                    <img src="./assets/images/bg-shape-3.png" alt="shape" />
                </div>
                <div class="row">
                    <div class="col-lg-8">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li><a href="/"><i class="fa fa-home"></i> Home</a></li>
                                <li class="active">Sustainability</li>
                            </ol>
                        </nav>
                        <h1>Sustainability</h1>
                    </div>
                </div>
            </div>
        </div>
      </div>

        <section class="about-area default-padding" id="Environmental"> 
            <div class="container">
                <div class="row">
                    <div class="col-xl-7 col-lg-6">
                        <div class="about-style-one-thumb animate fadeInUp animated" data-animate="fadeInUp">
                            <img src="assets/images/environment.png" alt="Image Not Found" />
                            <div class="fun-fact text-light animate fadeInDown animated" data-animate="fadeInDown" data-duration="1s">
                                <div class="counter">
                                    <div class="timer" data-to="38" data-speed="2000">1500</div>
                                    <div class="operator"> +</div>
                                </div>
                                <span class="medium">Trees Planted</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-5 col-lg-6 about-flex">
                        <div class="about-style-one">
                            <h2 class="title mb-30">Environmental Sustainability </h2>
                            <p>Protecting the environment is our responsibility, especially in a world with rapid carbon emissions. While we may not completely stop emissions, we can reduce them by planting and caring for trees. </p>
                            <p>Environmental sustainability means using Earth's resources wisely and keeping it healthy for present and future generations. This involves conserving nature, cutting down on pollution, and making eco-friendly choices like using clean energy, saving water, and reducing waste. It is a big task that requires everyone's involvement, from governments and businesses to individuals. The goal is to ensure our planet remains a good place to live for a long time. </p>
                            <ul class="list-simple">
                                <li>The ideal We need fresh air to breathe!</li> 
                            </ul> 
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="about-area pb-80" id="Education"> 
            <div class="container">
                <div class="row">
                    <div class="col-xl-5 col-lg-6 about-flex">
                        <div class="about-style-one">
                            <h2 class="title mb-30">Sustainable Education</h2>
                            <p>In our country, many women, especially in rural areas, face challenges in pursuing their dreams after marriage due to added responsibilities. IT jobs have been great opportunities for the past three decades, but rural, educated, and married women often couldn't access them without moving to big cities.</p>
                             <h6>Empowering Rural Women with Programming Skills</h6>
                             <p>The COVID-19 pandemic introduced remote work, which is a great opportunity for women who want to work from their hometowns. However, many of these women lack the technical skills needed for remote jobs. Pixalive has a solution: they are selecting deserving, economically disadvantaged women from rural areas and providing them with free programming training through Pixalive Labs. After the training, these women can work in Pixalive's SaaS division, gaining valuable experience that can change their lives for the better. Pixalive also helps place them in client companies. Moreover, they can work as freelancers to meet their financial needs because Pixalive has equipped them with programming knowledge. </p>
                             <p>In short, Pixalive is empowering rural women through free programming training, giving them job opportunities, and helping them achieve their dreams.</p>
                            <ul class="list-simple">
                                <li>The ideal We need fresh air to breathe!</li> 
                            </ul> 
                        </div>
                    </div>
                    <div class="col-xl-7 col-lg-6 about-flex">
                        <div class="about-style-one-thumb animate fadeInUp animated" data-animate="fadeInUp">
                            <img src="assets/images/education.png" alt="Image Not Found" />
                            <div class="fun-fact text-light animate fadeInDown animated" data-animate="fadeInDown" data-duration="1s">
                                <div class="counter">
                                    <div class="timer" data-to="38" data-speed="2000">20</div>
                                    <div class="operator"> +</div>
                                </div>
                                <span class="medium">Rural Women Trained</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    
      
      <Footer/>

  </> 
  ); 
  } 
  export default Sustainability;