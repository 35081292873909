import Header from '../../components/Header';  
import Footer from '../../components/Footer';
function PixaliveappisNowtrendinginIndia() { 
	return( 
	<>  
    
      <Header /> 
      
      <div class="breadcrumb-area bg-gray bg-cover">
        <div class="container">
            <div class="breadcrumb-item">
                <div class="breadcrum-shape">
                    <img src="./assets/images/bg-shape-3.png" alt="shape" />
                </div>
                <div class="row">
                    <div class="col-lg-8">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li><a href="/Blogs"><i class="fa fa-home"></i>Blog</a></li>
                                <li class="active">Blog Detail</li>
                            </ol>
                        </nav>
                        <h1>Blog Detail</h1>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div class="paragraph">
         <div class="container">
            <div class="row articles-lower ">
               <div class="col-md-8">
                     <div class="article_title">
                        <div class="editorial">
                           <a href="#">Social Media</a>
                        </div>
                        <h2 class="node__title">
                           <span>Pixalive app is Now trending in India!</span>
                        </h2>
                        <div class="short-desc">
                        Pixalive is an Indian app with its series of features, is the recent trend of social media in India.
                        </div>
                     </div>
                     <div class="articles-upper">
                        <div class="authors authors-box"> 
                           <div class="blogger-inforow">
                              <div class="blogger-pic">
                                 <img src="../assets/images/team/vignesh.jpg" alt="Jaydeep Saha " title="Jaydeep Saha "/>
                              </div>
                              <div class="blogger-details">
                                 <div class="blogger-name">Vignesh Dhanasekaran </div>
                                 <div class="blogger-designation">COO, Pixalive Group </div>
                              </div>
                           </div>
                           <div class="article-details">
                              <div class="date desktop">
                                 <div class="field field--name-field-page-publish-date field--type-datetime field--label-hidden field__item">
                                    <time datetime="2023-08-29T12:00:00Z" class="datetime">August 29, 2023</time>
                                 </div>
                              </div>
                              <div class="read-time desktop">7 minutes</div>
                              <div class="socila-media">
                                 <span>Share</span>
                                 <ul>
                                    <li>
                                    <a href="#" aria-label="facebook" target="blank" >
                                       <img class="img-fluid" src="./assets/images/facebook.svg" /> 
                                    </a>
                                    </li>
                                    <li>
                                    <a href="#" aria-label="twitter" target="blank">
                                       <img class="img-fluid" src="./assets/images/twitter.svg" />
                                    </a>
                                    </li>

                                    <li>
                                    <a href="#" aria-label="linkedin" target="blank">
                                       <img class="img-fluid" src="./assets/images/linkedin.svg" />
                                    </a>
                                    </li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                        <div class="article-image"> 
                           <img src="../assets/images/blog/Now-trending-in-India.webp" alt=""/>
                        </div>                       
                     </div>
                     <div class="articles-lower">
                        <div class="article-body">
                           <div class="field--name-body field--name-body-1">                         
                              <h6>Chinese apps ban:</h6>    
                              <p>Ever since the ban of the Chinese app by The Ministry of Electronics and Information Technology, India, social media users have been in a turbulence. </p>
                              <p>Initially, 59 mostly used Chinese apps were banned, recently 47 other apps were banned, reasoning that they were the clones of the 59 previously prohibited apps. </p>
                              <p>Moreover, they have prepared a list that contains 250 different apps that will undergo a security check before facing the ban.</p>
                              <p>Indians must have been dependent on at least a few of these apps. Even though the ban was to safeguard the safety and privacy of the Indian users, it could have disturbed the app users’ day-to-day situation.</p>
                              <p><strong class="ew">Finding an app replacer for all those banned apps:</strong></p>
                               
                              <p>Immediately after the ban, Indians started haunting for an Indian app replacer for all the banned Chinese apps that they were using in their daily routine to resume their activities. </p>
                              <p>Unquestionably there exist some or the other Indian replacement for all those prohibited apps. The hunt is open to all the people who would go for an Indian alternative.</p>
                              <p>There are many apps with Indian origin, but most of the apps run with the preponderance of Chinese investments. Pixalive is one of the apps that run without the support of the Chinese investments. </p>
                              <p>Pixalive has its mutual collaboration with Canadian Nrich Group and the Indian K2D Multidisciplinary Services Private Limited for their future developments.</p>
                              <p><strong class="ew">Pixalive and its bulk of features:</strong></p>
                              <h6>Create Post:</h6>    
                              <p>Users can create posts with Photos, Videos, Texts. A unique feature allows the users to include Voice notes for each post. As usual, tagging, hashtags, and trending options are available for each post.</p>
                              <p></p>

                              <h6>Stories:</h6>    
                              <p>Pixalive users can create stories on their timeline that gets displayed for 24 hours.</p>

                              <h6>Chat:</h6>    
                              <p>Chat can be one on one and also group chat that holds 100 people.</p>

                              <h6>Calls:</h6>    
                              <p>Smooth, Media-Rich Voice and Video calling facilities with either one on one or a group that holds 17 members in one single room is possible.</p>

                              <h6>Channel Creation:</h6>    
                              <p>Channels for your brand or ideas in a social media platform is a must nowadays, Pixalive allows the users to create their Channel to establish and grow their brand or ideas.</p>

                              <h6>News:</h6>    
                              <p>Encounter the 24*7 latest NEWS updates on a social media platform, with filters that display City-wise NEWS at your doorstep.</p>

                              <h6>Games:</h6>    
                              <p>There are more than 350 games inbuilt in this app. Cash awarding games like Qureka Quiz are available that allows the users to earn real-time cash prizes.</p>

                              <h6>Trending Contests and Challenges:</h6>    
                              <p>Pixalive offers its user with trending contests and Challenges where the users can just participate and get trending with their posts and win cash prizes.</p>
                              <p>All these features in a single social media app is a boon for social media users. Pixalive is being the current trending social media of today. Join the app and get benefitted out of a complete social media.</p>
                              
                           </div>
                        </div>
                        
                        <div class="article-body mobile-show">
                           <div class="articles-tags">TAGS: <div class="field field--name-field-free-tags field--type-entity-reference field--label-hidden field__items">
                              <div class="field__item">Social Media</div>
                              <div class="field__item">Trending</div>
                              <div class="field__item">Hashtag</div> 
                              <div class="field__item">Startup</div>
                              <div class="field__item">Fundraising</div>
                              <div class="field__item">Investing</div>
                              <div class="field__item">Technology</div>
                           </div>
                           </div>
                           <div class="socila-media">
                           <span>Share On</span>
                           <ul>
                              <li>
                                 <a href="#" aria-label="facebook" target="blank" >
                                    <img class="img-fluid" src="./assets/images/facebook.svg" /> 
                                 </a>
                              </li>
                              <li>
                                 <a href="#" aria-label="twitter" target="blank">
                                    <img class="img-fluid" src="./assets/images/twitter.svg" />
                                 </a>
                              </li>
                              <li>
                                 <a href="#" aria-label="linkedin" target="blank">
                                    <img class="img-fluid" src="./assets/images/linkedin.svg" />
                                 </a>
                              </li>
                           </ul>
                           <div class="copy-link">
                              <span>Copy link</span>
                              <a id="copy_link" href="javascript:;" cururl="#">
                                 <img src="../assets/images/copy-link.png" alt="copy-link" width="100" height="100"/>
                              </a> 
                           </div>
                        </div>
                     </div>
                  </div>                        
               </div>

               <div class="col-md-4 cta-forms">
                  <div class="inner-cta-forms-trends mobile-f-w"> 
                     <div class="right-banner">
                        <div class="field field--name-field-sidebar-ad field--type-entity-reference field--label-hidden field__item">
                           <div class="eck-entity">
                           <div class="right-banner"> 
                                 <img src="../assets/images/ads.jpg" title=""/>
                              </div>
                              <div class="content">
                                 <h4 class="base-color-white brand-no-case">Golden Period</h4>
                                 <p>Pixalive inaugurated its new office space in Bengaluru on August 28, 2023, marking the beginning of a golden era for the company. </p>
                                 <p>Within just one month, on September 27, 2023, it secured a dedicated office space and already employed more than 30 individuals.</p>
                                 <p>It has expanded into five new divisions apart from its Social Media division, including Startup Support, SaaS, IT Services, Software Training, and Sustainability.</p>
                                 <a class="arrow-btn-white" href="/PixaliveBengaluruOffice">View More</a>                              
                              </div> 
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

            </div>
         </div> 
      </div>
      
      <Footer/>

  </> 
  ); 
  } 
  export default PixaliveappisNowtrendinginIndia;