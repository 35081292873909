import Header from '../../components/Header';  
import Footer from '../../components/Footer';
function JobApply() { 
	return( 
	<>  
    
      <Header /> 
      <div class="breadcrumb-area bg-gray bg-cover">
        <div class="container">
            <div class="breadcrumb-item">
                <div class="breadcrum-shape">
                    <img src="./assets/images/bg-shape-3.png" alt="shape" />
                </div>
                <div class="row">
                    <div class="col-lg-8">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li><a href="/Careers"><i class="fa fa-home"></i>Careers</a></li>
                                <li class="active">Job Apply</li>
                            </ol>
                        </nav>
                        <h1>Job Apply</h1>
                    </div>
                </div>
            </div>
        </div>
      </div>

      <article role="article" class="node node--type-kenexa-jobs node--view-mode-full clearfix" id="training-hiring-programs" >
        <div class="section-jobs-page contact-form-style-one contact-form-style-one-1 section-header">
            <div class="container">
                {/* <div class="page-title">
                    <h2>Job Description</h2>
                </div> */}
                <div class="node__content clearfix">
                    <h3 class="node__title jobs-title custom-text-uppercase">
                    <span class="field field--name-title field--type-string field--label-hidden">Job Apply</span>
                    </h3>
                <div class="contact-form">
                            <form id="contact-form" action="#" class="contact-form contact-form mt-30">
                                <div class="row">
                                    <div class="col-lg-4">
                                        <div class="form-box mb-30">
                                            <label>Name <span>*</span></label>
                                            <input class="form-control"  type="text" name="name" placeholder="Your Name"/>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-box mb-30">
                                            <label>Mobile No <span>*</span></label>
                                            <input class="form-control"  type="text" name="phone" placeholder="Your Mobile No"/>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-box mb-30">
                                            <label>Email ID <span>*</span></label>
                                            <input class="form-control"  type="text" name="email" placeholder="Your Email ID"/>
                                        </div>
                                    </div>
                                    {/* <div class="col-lg-4">
                                        <div class="form-box mb-30">
                                            <label>Program <span>*</span></label>
                                            <select>
                                                <option>Select Program</option>
                                                <option>MERN Full Stack Development</option>
                                                <option>MEAN Full Stack Development</option>
                                                <option>Flutter with MongoDB NodeJS Development</option>
                                            </select>
                                        </div>
                                    </div> */}
                                    
                                    <div class="col-lg-4">
                                        <div class="form-box mb-30">
                                            <label>City</label>
                                            <input class="form-control"  type="text" name="subject" placeholder="City"/>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-box mb-30">
                                            <label>Pincode</label>
                                            <input class="form-control" type="text" name="subject" placeholder="Pincode"/>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-box mb-30">
                                            <label>Qualification<span>*</span></label>
                                            <input class="form-control"  type="text" name="subject" placeholder="Qualification"/>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-box mb-30">
                                            <label>Gender <span>*</span></label>
                                            <select>
                                                <option>Male</option>
                                                <option>Female</option> 
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-box mb-30">
                                            <label>LinkedIn Profile<span>*</span></label>
                                            <input class="form-control"  type="text" name="subject" placeholder="LinkedIn Profile"/>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-box mb-30">
                                            <label>Current Employer<span>*</span></label>
                                            <input class="form-control"  type="text" name="subject" placeholder="Current Employer"/>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-box mb-30">
                                            <label>Experience in Years<span>*</span></label>
                                            <select>
                                                <option>0 to 1 Years</option>
                                                <option>1 to 2 Years</option> 
                                                <option>2 to 3 Years</option> 
                                                <option>3 to 4 Years</option> 
                                                <option>4 to 5 Years</option> 
                                                <option>5+ Years</option> 
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-box mb-30">
                                            <label>Willing to Relocate<span>*</span></label>
                                            <select>
                                                <option>Yes</option>
                                                <option>No</option> 
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-8">
                                        <div class="form-box mb-30 form-box-display">
                                            <label>Resume <span>*</span></label>
                                            <input type="file" name="subject" placeholder="Resume"/>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        {/* <div class="form-box message-icon mb-30">
                                            <label>Message <span>*</span></label>
                                            <textarea class="form-control"  name="message" id="message" cols="30" rows="10" placeholder="Your Message"></textarea>
                                        </div> */}
                                        <div class="contact-btn text-center">
                                            <button class="btn btn btn-primary" type="submit">Apply Now</button>
                                        </div>
                                    </div>
                                </div>
                            </form> 
                        </div>
                        
                </div>
            </div>
        </div>
        </article>
      
      <Footer/>

  </> 
  ); 
  } 
  export default JobApply;