import Header from '../../components/Header';  
import Footer from '../../components/Footer';
import GroupWebsite from '../Home/GroupWebsite';
function AboutUs() { 
	return( 
	<>  
    
   <Header />  

   <div class="breadcrumb-area bg-gray bg-cover">
        <div class="container">
            <div class="breadcrumb-item">
                <div class="breadcrum-shape">
                    <img src="./assets/images/bg-shape-3.png" alt="shape" />
                </div>
                <div class="row">
                    <div class="col-lg-8">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li><a href="/"><i class="fa fa-home"></i> Home</a></li>
                                <li class="active">Our Company</li>
                            </ol>
                        </nav>
                        <h1>Our Company</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
 
 
      <section class="intro-section">
         <div class="container">
            <div class="row">
               <div class="col-12 col-lg-2 order-2"></div>
               <div class="col-lg-5 text-intro-box d-flex align-items-center order-1 text-content-left">
               <div class="pr-0 pr-md-0 intro-content">
                  <h3 class="intro-description">About Us</h3>
                  <div class="intro-text">
                     <p>Pixalive Group is a diverse organization based in Bengaluru, Karnataka, India, encompassing six key sectors: <br/><strong>Social Media, SaaS, IT Services, Startups, Software training & Sustainability. </strong></p>
                     <p>Its multi-sector presence promises significant benefits to the nation.</p>
                  </div>
               </div>
               </div>
               <div class="col-lg-5 intro-player-box order-2 ">
               <div class="text-with-assets-image-div d-flex align-items-center h-100 w-100">
                  <div class="text-with-assets-image-innr-div w-100">
                     <div class="responsiveContainer">
                        <img src="./assets/images/abt.jpg" class="fit-cover" alt="" /> 
                     </div>
                  </div>
               </div>
               </div>
            </div>
         </div>
      </section>
      <section class="section stories black-bg">
         <div class="container">
            <div class="row">
               <div class="col-12">
                  <div class="about-tab">
                     <div class="section-header text-white">
                        <h2 class="heading" data-aos="fade-up" data-aos-offset="100" data-aos-duration="650" data-aos-easing="ease-in-out">Sectors</h2>
                     </div>
                     <div class="tab-nav">
                        <ul class="nav" data-aos="fade-up" data-aos-offset="100" data-aos-duration="650" data-aos-easing="ease-in-out">
                           <li class="nav-item">
                              <a class="nav-link active" href="#Social-Media">Social Media</a>
                           </li>
                           <li class="nav-item">
                              <a class="nav-link" href="#SaaS">SAAS</a>
                           </li>
                           <li class="nav-item">
                              <a class="nav-link" href="#Startups">Startups</a>
                           </li>
                           <li class="nav-item">
                              <a class="nav-link" href="#Consultancy-Services">Software Services</a>
                           </li>
                           <li class="nav-item">
                              <a class="nav-link" href="#Software-Training">Software Training</a>
                           </li>
                           <li class="nav-item">
                              <a class="nav-link" href="#Sustainability">Sustainability</a>
                           </li>
                        </ul>
                     </div>
                  </div>
                  <div class="tab-content intro-description-tab">
                     <div class="tab-pane fade show active" id="Social-Media">
                        <div class="flickity-slider-mobile-wrap" data-aos="fade-up" data-aos-offset="100" data-aos-duration="650" data-aos-easing="ease-in-out">
                           <div class="row">
                              <div class="col-lg-5 text-intro-box d-flex align-items-center order-1 text-content-left">
                                 <div class="pr-0 pr-md-0 intro-content">
                                    <h3 class="intro-description ">Social Media</h3>
                                    <div class="intro-text ">
                                       <p>Our platform serves as a networking tool and a privacy-centric solution for promoting businesses.</p>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-12 col-lg-2 order-2"></div>
                              <div class="col-lg-5 intro-player-box order-3">
                                 <div class="text-with-assets-image-div d-flex align-items-center h-100 w-100">
                                    <div class="text-with-assets-image-innr-div w-100">
                                       <img  src="./assets/images/au.jpg" alt="green practices" />
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>  
                     <div class="tab-pane fade" id="SaaS">                      
                        <div class="flickity-slider-mobile-wrap" data-aos="fade-up" data-aos-offset="100" data-aos-duration="650" data-aos-easing="ease-in-out">
                           <div class="row">
                              <div class="col-lg-5 intro-player-box order-1">
                                 <div class="text-with-assets-image-div d-flex align-items-center h-100 w-100">
                                    <div class="text-with-assets-image-innr-div w-100">
                                       <img  src="./assets/images/about-saas.jpg" alt="green practices" />
                                    </div>
                                 </div>
                              </div>
                              <div class="col-12 col-lg-1 order-2"></div>
                              <div class="col-lg-6 text-intro-box d-flex align-items-center order-3 text-content-left">
                                 <div class="pr-0 pr-md-0 intro-content">
                                    <h3 class="intro-description">SAAS</h3>
                                    <div class="intro-text ">
                                       <p>We specialize in developing high-quality software tools for both startups and established companies, providing affordability without compromising on features.</p>
                                    </div>
                                 </div>
                              </div> 
                           </div> 
                        </div>
                     </div>                    
                     
                     <div class="tab-pane fade" id="Startups">
                        <div class="flickity-slider-mobile-wrap" data-aos="fade-up" data-aos-offset="100" data-aos-duration="650" data-aos-easing="ease-in-out">
                           <div class="row">
                              <div class="col-lg-5 text-intro-box d-flex align-items-center order-1 text-content-left">
                                 <div class="pr-0 pr-md-0 intro-content">
                                 <h3 class="intro-description">Startups</h3>
                                    <div class="intro-text">
                                       <p>We offer comprehensive support, spanning ideation, technical assistance, digital marketing, legal compliance, and financial guidance.</p>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-12 col-lg-2 order-2"></div>
                              <div class="col-lg-5 intro-player-box order-3">
                                 <div class="text-with-assets-image-div d-flex align-items-center h-100 w-100">
                                 <div class="text-with-assets-image-innr-div w-100">
                                    <img  src="./assets/images/about-statup.jpg" alt="green practices" />
                                 </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="tab-pane fade" id="Consultancy-Services">                      
                        <div class="flickity-slider-mobile-wrap" data-aos="fade-up" data-aos-offset="100" data-aos-duration="650" data-aos-easing="ease-in-out">
                           <div class="row">
                              <div class="col-lg-5 intro-player-box order-1">
                                 <div class="text-with-assets-image-div d-flex align-items-center h-100 w-100">
                                    <div class="text-with-assets-image-innr-div w-100">
                                       <img  src="./assets/images/about-it-services.jpg" alt="green practices" />
                                    </div>
                                 </div>
                              </div>
                              <div class="col-12 col-lg-1 order-2"></div>
                              <div class="col-lg-6 text-intro-box d-flex align-items-center order-3 text-content-left">
                                 <div class="pr-0 pr-md-0 intro-content">
                                    <h3 class="intro-description">Pixalive Consultancy Services</h3>
                                    <div class="intro-text ">
                                       <p>We excel in offering a comprehensive range of IT services, digital services, consulting, business solutions, project management, and talent outsourcing all within a single platform, making us a leader in the Indian market.</p>
                                    </div>
                                 </div>
                              </div> 
                           </div> 
                        </div>
                     </div>

                     <div class="tab-pane fade" id="Software-Training">
                        <div class="flickity-slider-mobile-wrap" data-aos="fade-up" data-aos-offset="100" data-aos-duration="650" data-aos-easing="ease-in-out">
                           <div class="row">
                              <div class="col-lg-5 text-intro-box d-flex align-items-center order-1 text-content-left">
                                 <div class="pr-0 pr-md-0 intro-content">
                                 <h3 class="intro-description">Software Training</h3>
                                    <div class="intro-text">
                                       <p>We focus on corporate training and skill development, providing a range of technology programs that help people get better at their jobs and prepare for shifts in the business world. </p>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-12 col-lg-2 order-2"></div>
                              <div class="col-lg-5 intro-player-box order-3">
                                 <div class="text-with-assets-image-div d-flex align-items-center h-100 w-100">
                                 <div class="text-with-assets-image-innr-div w-100">
                                    <img  src="./assets/images/about-training.jpg" alt="green practices" />
                                 </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="tab-pane fade" id="Sustainability">                      
                        <div class="flickity-slider-mobile-wrap" data-aos="fade-up" data-aos-offset="100" data-aos-duration="650" data-aos-easing="ease-in-out">
                           <div class="row">
                              <div class="col-lg-5 intro-player-box order-1">
                                 <div class="text-with-assets-image-div d-flex align-items-center h-100 w-100">
                                    <div class="text-with-assets-image-innr-div w-100">
                                       <img  src="./assets/images/about-sustainability.jpg" alt="green practices" />
                                    </div>
                                 </div>
                              </div>
                              <div class="col-12 col-lg-1 order-2"></div>
                              <div class="col-lg-6 text-intro-box d-flex align-items-center order-3 text-content-left">
                                 <div class="pr-0 pr-md-0 intro-content">
                                    <h3 class="intro-description">Sustainability</h3>
                                    <div class="intro-text ">
                                       <p>Pixalive Foundation is the Corporate Social Responsibility (CSR) activity division of Pixalive Group. Focuses sustainability in Environment & Education.</p>
                                    </div>
                                 </div>
                              </div> 
                           </div> 
                        </div>
                     </div>
                           
                  </div>
               </div>
            </div>
         </div>
      </section>

      <section class="section">
         <div class="container" data-aos="fade-up" data-aos-offset="100" data-aos-duration="650" data-aos-easing="ease-in-out">
            <div class="row"> 
               <div class="col-md-3 col-12">
                  <div class="stats-card" >                                                
                     <h5>Team Strength</h5>
                     <div class="stats-card-value">40+</div>
                     {/* <p>workforce globally distributed highly localized</p>  */}
                  </div>
               </div>
               <div class="col-md-3 col-12">
                  <div class="stats-card">                                                
                     <h5>Trees Planted</h5>
                     <div class="stats-card-value">1.5K+</div>
                     {/* <p class="stats-card-content-para">workforce globally distributed highly localized</p>  */}
                  </div>
               </div>
               <div class="col-md-3 col-12">
                  <div class="stats-card" >                                                
                     <h5>Projects Completed</h5>
                     <div class="stats-card-value">27+</div>
                     {/* <p>workforce globally distributed highly localized</p>  */}
                  </div>
               </div>
               <div class="col-md-3 col-12">
                  <div class="stats-card">                                                
                     <h5>Supported Startups </h5>
                     <div class="stats-card-value">7</div>
                     {/* <p class="stats-card-content-para">workforce globally distributed highly localized</p>  */}
                  </div>
               </div>
            </div>
         </div>
      </section>



      <section class="intro-section bg-gray">
         <div class="container">
            <div class="row">
               <div class="col-12 col-lg-2 order-2"></div>
               <div class="col-lg-5 intro-player-box order-2 ">
                  <div class="text-with-assets-image-div d-flex align-items-center h-100 w-100">
                     <div class="text-with-assets-image-innr-div w-100">
                        <div class="responsiveContainer">
                           <img src="./assets/images/vision.jpg" class="fit-cover" alt="" /> 
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-lg-5 text-intro-box d-flex align-items-center order-1 text-content-left">
                  <div class="pr-0 pr-md-0 intro-content">
                     <h3 class="intro-description">Vision</h3>
                     <div class="intro-text">
                        <p>Pixalive Group aspires to be a global leader in sectors that improve lives and benefit nations, including Social Media, SaaS, Startups, Services, Software Training & Sustainability through value-driven endeavours.</p>
                     </div>
                  </div>
               </div>
              
            </div>
         </div>
      </section>



      <section class="section section-value">
         <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xxs-12">
            <div class="container"> 
               <div class="row bottomspace25 pb-lg-4 text-center">
                     <div class="col-md-12">
                        <h2 class="text-blue">Values</h2>
                     </div>
                     <div class="col-md-12">
                        <p>We aim to conduct our businesses ethically and in a manner that is respectful to all. We achieve this by aligning our goals with our six core values.</p>
                     </div>
                  </div>
                 
                  <div class="row bottomspace25">
                  <div class="col-md-12 text-center">
                     <div class="value-block">
                        <span class="active">
                              <div class="lab_item">
                                 <div class="hexagon hexagon2">
                                    <div class="hexagon-in1">
                                          <div class="hexagon-in2">
                                             <div class="hexagon-content">
                                                   <h3>Growth Potential</h3>
                                                <p>Prospects for future growth and expansion can positively impact a company's value.</p>
                                             </div>
                                          </div>
                                    </div>
                                 </div>
                              </div>
                        </span>
                        <span>
                              <div class="lab_item">
                                 <div class="hexagon hexagon2">
                                    <div class="hexagon-in1">
                                          <div class="hexagon-in2">
                                             <div class="hexagon-content">
                                                <h3>Brand Reputation</h3>
                                                <p>A strong and positive brand image can significantly enhance a company's value.</p>
                                             </div>
                                          </div>
                                    </div>
                                 </div>
                              </div>
                        </span>
                        <span>
                           <div class="lab_item">
                              <div class="hexagon hexagon2">
                                 <div class="hexagon-in1">
                                       <div class="hexagon-in2">
                                          <div class="hexagon-content">
                                             <h3>Revenue & Profitability</h3>
                                             <p>Companies that generate substantial revenue and maintain profitability tend to have higher value.</p>
                                          </div>
                                       </div>
                                 </div>
                              </div>
                           </div>
                        </span>
                        
                        <span >
                           <div class="lab_item">
                              <div class="hexagon hexagon2">
                                 <div class="hexagon-in1">
                                       <div class="hexagon-in2">
                                          <div class="hexagon-content">
                                             <h3>Innovation</h3>
                                          <p>Developing new and improved products, technologies, or solutions that solve problems or create new opportunities.</p>
                                          </div>
                                       </div>
                                 </div>
                              </div>
                           </div>
                        </span>
                        <span>
                           <div class="lab_item">
                              <div class="hexagon hexagon2">
                                 <div class="hexagon-in1">
                                       <div class="hexagon-in2">
                                          <div class="hexagon-content">
                                             <h3>Product or Service Quality</h3>
                                             <p>Providing high-quality products or services that meet or exceed customer expectations.</p>
                                          </div>
                                       </div>
                                 </div>
                              </div>
                           </div>
                        </span>
                        <span>
                           <div class="lab_item">
                              <div class="hexagon hexagon2">
                                 <div class="hexagon-in1">
                                       <div class="hexagon-in2">
                                          <div class="hexagon-content">
                                             <h3>Trust</h3>
                                          <p>We shall believe in our employees and Shareholders</p>
                                          </div>
                                       </div>
                                 </div>
                              </div>
                           </div>
                        </span>
                        <span>
                           <div class="lab_item">
                              <div class="hexagon hexagon2">
                                 <div class="hexagon-in1">
                                       <div class="hexagon-in2">
                                          <div class="hexagon-content">
                                             <h3>Courage</h3>
                                             <p>We shall embrace new ideas and businesses</p>
                                          </div>
                                       </div>
                                 </div>
                              </div>
                           </div>
                        </span>
                        <span>
                           <div class="lab_item">
                              <div class="hexagon hexagon2">
                                 <div class="hexagon-in1">
                                       <div class="hexagon-in2">
                                          <div class="hexagon-content">
                                             <h3>Commitment</h3>
                                             <p>We shall stand by our promises and adhere to higher standards of business</p>
                                          </div>
                                       </div>
                                 </div>
                              </div>
                           </div>
                        </span>

                        <div class="poly-links">
                           <ul>
                              <li><a href="javascript:;"   data-value="customer-values" class="links customer-value"> Growth Potential</a></li>
                              <li><a href="javascript:;" data-value="respect" class="links respect">Brand Reputation</a></li>
                              <li><a href="javascript:;" data-value="ownership-mindset" class="links ownership-mindset">Revenue & Profitability</a></li>
                              <li><a href="javascript:;" data-value="integrity" class="links integrity">Innovation</a></li>
                              <li><a href="javascript:;" data-value="excellence" class="links excellence">Product or Service Quality</a></li>
                              <li><a href="javascript:;" data-value="one-team" class="links one-team">Trust</a></li>
                              <li><a href="javascript:;" data-value="courage" class="links courage">Courage</a></li>
                              <li><a href="javascript:;" data-value="commitment" class="links commitment">Commitment</a></li>
                              </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

         </div>
         </section>

    <section id="hear-from-our-leaders" class="section  about-leader">
      <div class="container">
         <div class="section-header text-center mb-20">
            <h2 class="heading font-regular mb-20" data-aos="fade-up" data-aos-offset="100" data-aos-duration="650" data-aos-easing="ease-in-out">"One Vision, One Force: Leading Together"</h2>
            <p data-aos="fade-up" data-aos-offset="100" data-aos-duration="650" data-aos-easing="ease-in-out">Information and profiles of the Founder, Co-Founders, Board of Directors, Senior Leadership, and Committee Members at Pixalive Group</p>
         </div>

            <div class="row">  
               <div class="team-md-3 col-md-3 mb-30 wow fadeInUp">
                  <a class="team-style-one">
                     <div class="thumb">
                     <img src="../assets/images/team/rajasekar.jpg" alt="Mr. Rajasekar Sundaresan" /> 
                     </div>
                     <div class="content"> 
                        <h4>Mr. Rajasekar Sundaresan</h4>
                        <span>CEO, Pixalive Platforms</span> 
                     </div>
                  </a>
               </div>  
               <div class="team-md-3 col-md-3 mb-30 wow fadeInUp" data-wow-delay="300ms">
                  <div class="team-style-one">
                     <div class="thumb">
                     <img src="../assets/images/team/vignesh.jpg" alt="Vignesh Dhanasekaran" /> 
                     </div>
                     <div class="content">  
                        <h4>Mr. Vignesh Dhanasekaran</h4>
                        <span>Group COO</span> 
                     </div>
                  </div>
               </div>
               <div class="team-md-3 col-md-3 mb-30 wow fadeInUp">
                  <a class="team-style-one">
                     <div class="thumb">
                     <img src="../assets/images/team/3.jpg" alt="Mr. Nitesh Jain " /> 
                     </div>
                     <div class="content"> 
                        <h4>Mr. Nitesh Jain </h4>
                        <span>Director, Pixalive Platforms</span> 
                     </div>
                  </a>
               </div> 
               <div class="team-md-3 col-md-3 mb-30 wow fadeInUp">
                  <a class="team-style-one">
                     <div class="thumb">
                     <img src="../assets/images/team/4.jpg" alt="Mr. Abhishek Jain" /> 
                     </div>
                     <div class="content"> 
                        <h4>Mr. Abhishek Jain</h4>
                        <span>Director, Pixalive Platforms</span> 
                     </div>
                  </a>
               </div> 
               
               <div class="team-md-3 col-md-3 mb-30 wow fadeInUp">
                  <a class="team-style-one">
                     <div class="thumb">
                     <img src="../assets/images/team/2.jpg" alt="Mr. Bharath Kumar Jain" /> 
                     </div>
                     <div class="content"> 
                        <h4>Mr. Bharath Kumar Jain</h4>
                        <span>Legal Advisor to CEO</span> 
                     </div>
                  </a>
               </div>
               
                
               <div class="team-md-3 col-md-3 mb-30 wow fadeInLeft" data-wow-delay="700ms">
                  <div class="team-style-one">
                     <div class="thumb">
                     <img src="../assets/images/team/sathish.jpg" alt="Sathish Kumar" />
                     </div>
                     <div class="content">  
                        <h4>Mr. Sathish Kumar</h4>
                        <span>Technical Advisor to CEO</span> 
                     </div>
                  </div>
               </div> 
               <div class="team-md-3 col-md-3 mb-30 wow fadeInUp" data-wow-delay="500ms">
                  <div class="team-style-one">
                     <div class="thumb">
                     <img src="../assets/images/team/murali.jpg" alt="Murali Sundaresan" /> 
                     </div>
                     <div class="content">  
                        <h4>Mr. Murali Sundaresan</h4>
                        <span>Director, Pixalive Platforms</span> 
                     </div>
                  </div>
               </div> 
               <div class="team-md-3 col-md-3 mb-30 wow fadeInUp" data-wow-delay="500ms">
                  <div class="team-style-one">
                     <div class="thumb">
                     <img src="../assets/images/team/venkat.jpg" alt="venkat" /> 
                     </div>
                     <div class="content">  
                        <h4>Mr. R Venkatesh</h4>
                        <span>CMO, Pixalive Labs</span> 
                     </div>
                  </div>
               </div> 
               <div class="team-md-3 col-md-3 mb-30 wow fadeInLeft" data-wow-delay="700ms">
                  <div class="team-style-one">
                     <div class="thumb">
                     <img src="../assets/images/team/viji.jpg" alt="Viji Vaithi" /> 
                     </div>
                     <div class="content">  
                        <h4>Mr. Viji Vaithi </h4>
                        <span>Group UI/UX Design Lead</span> 
                     </div>
                  </div>
               </div>  
         </div>
      </div>
   </section>
      
    <GroupWebsite/> 
   <Footer/>

  </> 
  ); 
  } 
  export default AboutUs;