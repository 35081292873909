import Header from '../../components/Header';  
import Footer from '../../components/Footer';
function Careers() { 
	return( 
	<>  
    
      <Header /> 
      <div class="breadcrumb-area bg-gray bg-cover">
        <div class="container">
            <div class="breadcrumb-item">
                <div class="breadcrum-shape">
                    <img src="./assets/images/bg-shape-3.png" alt="shape" />
                </div>
                <div class="row">
                    <div class="col-lg-8">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li><a href="/"><i class="fa fa-home"></i> Home</a></li>
                                <li class="active">Careers</li>
                            </ol>
                        </nav>
                        <h1>Careers</h1>
                    </div>
                </div>
            </div>
        </div>
      </div>

      <section class="section supercharging-highlighted-wrap">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 about-flex">
                    <div class="supercharging-highlight-infobox">
                        <h2>Working Culture</h2>
                        <h6>Embrace a big future with us!</h6>
                        <p>The working culture of an office is like the way people act and behave while they're working together. A good working culture means people talk to each other, help each other, and feel good about their jobs. Here are some important things in a good working culture:</p>
                        <p>The working culture of an office refers to the collective behaviours, values, and norms that shape how employees interact, collaborate, and conduct themselves in the workplace. A positive working culture is essential for employee well-being, job satisfaction, and overall productivity. Here are some key aspects of office working culture:</p>
                        <div class="supercharging-highlight-list">
                            {/* <div class="highlight-list-inner">                
                                <div class="highlight-list-item">
                                    <div class="highlight-list-icon">
                                    <img alt="Enriching job experiences" class="img-fluid" src="/images/components/supercharging-highligh-icon-2.svg" />
                                    </div>
                                    <div class="highlight-list-text">
                                    <p>Enriching job experiences</p>
                                    </div>
                                </div>
                                <div class="highlight-list-item">
                                    <div class="highlight-list-icon">
                                    <img alt="An employment that you can trust in" class="img-fluid" src="/images/components/supercharging-highligh-icon-3.svg" />
                                    </div>
                                    <div class="highlight-list-text">
                                    <p>An employment that you can trust in</p>
                                    </div>
                                </div>                    
                            </div> 
                            <div class="supercharging-action">
                                <a class="arrow-btn-purple" href="#hiring-process">How we hire</a>
                            </div>*/}
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 about-flex">
                    <img src="assets/images/carrer.jpg" alt="Image Not Found" />
                </div>
            </div>
        </div>
        </section>
        <section class="section bg-gray">
            <div class="container">                       
                <div class="section-header pb-lg-4 text-center">
                <h2 class="heading font-regular" data-aos="fade-up" data-aos-offset="100" data-aos-duration="650" data-aos-easing="ease-in-out">Current Openings</h2>
                <div class="only-value-content-counter">
                    <p class="width-70" data-aos="fade-up" data-aos-offset="100" data-aos-duration="650" data-aos-easing="ease-in-out">
                    Putting people first begins the moment we meet. Tell us about your skills and aspirations. Explore job opportunities that speak to your journey.
                    </p>
                </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-sm-4">
                        <a href="/Flutter" class="dept-card">
                            <div class="jb_types openings">03 Openings</div>
                            <img class="dept-card-icon" src="../assets/images/c1.png" al="note" />
                            <div class="clearfix"></div>
                            <h6>Flutter Developer</h6>
                            <p>Seeking a skilled Flutter developer to create cross-platform mobile applications</p>
                            <a href="/Flutter" class="btn btn-primary">View Jobs</a>
                        </a>
                    </div>
                    <div class="col-sm-4">
                        <a href="/MernStack" class="dept-card">
                            <div class="jb_types openings">05 Openings</div>
                            <img class="dept-card-icon" src="../assets/images/c2.png" al="note" />
                            <div class="clearfix"></div>
                            <h6>MERN Stack Developer</h6>
                            <p>Hiring a MERN stack developer to build and maintain full-stack web applications</p>
                            <a href="/MernStack" class="btn btn-primary">View Jobs</a>
                        </a>
                    </div>
                    <div class="col-sm-4">
                        <a href="/MeanStack" class="dept-card">
                            <div class="jb_types openings">05 Openings</div>
                            <img class="dept-card-icon" src="../assets/images/c3.png" al="note" />
                            <div class="clearfix"></div>
                            <h6>MEAN Stack Developer</h6>
                            <p>Seeking MEAN stack developer for dynamic web apps</p>
                            <a href="/MeanStack" class="btn btn-primary">View Jobs</a>
                        </a>
                    </div>
                    <div class="col-sm-4">
                        <a href="/UXDesign" class="dept-card">
                            <div class="jb_types openings">02 Openings</div>
                            <img class="dept-card-icon" src="../assets/images/c1.png" al="note" />
                            <div class="clearfix"></div>
                            <h6>UX/UI Designer </h6>
                            <p>Hiring UI/UX designer to create compelling user experiences</p>
                            <a href="/UXDesign" class="btn btn-primary">View Jobs</a>
                        </a>
                    </div>
                    <div class="col-sm-4">
                        <a href="/BusinessDevelopment" class="dept-card">
                            <div class="jb_types openings">04 Openings</div>
                            <img class="dept-card-icon" src="../assets/images/c2.png" al="note" />
                            <div class="clearfix"></div>
                            <h6>Business Development Associate </h6>
                            <p>Grow, promote and position the business as a global leader</p>
                            <a href="/BusinessDevelopment" class="btn btn-primary">View Jobs</a>
                        </a>
                    </div>
                    <div class="col-sm-4">
                        <a href="/SoftwareTesting" class="dept-card">
                            <div class="jb_types openings">03 Openings</div>
                            <img class="dept-card-icon" src="../assets/images/c3.png" al="note" />
                            <div class="clearfix"></div>
                            <h6>Software Testing</h6>
                            <p>Hiring software tester for quality assurance</p>
                            <a href="/SoftwareTesting" class="btn btn-primary">View Jobs</a>
                        </a>
                    </div>
                    <div class="col-sm-4">
                        <a href="/HumanResources" class="dept-card">
                            <div class="jb_types openings">02 Openings</div>
                            <img class="dept-card-icon" src="../assets/images/c1.png" al="note" />
                            <div class="clearfix"></div>
                            <h6>Human Resources</h6>
                            <p>Hiring HR manager for talent and organization</p>
                            <a href="/HumanResources" class="btn btn-primary">View Jobs</a>
                        </a>
                    </div>
                    {/* <div class="col-sm-4">
                        <a href="/Academics" class="dept-card">
                            <div class="jb_types openings">05 Openings</div>
                            <img class="dept-card-icon" src="../assets/images/c2.png" al="note" />
                            <div class="clearfix"></div>
                            <h6>Academics – Instructor, Trainer</h6>
                            <p>Bring teaching expertise to building powerful learning journeys for students</p>
                            <a href="/Academics" class="btn btn-primary">View Jobs</a>
                        </a>
                    </div> */}
                    <div class="col-sm-4">
                        <a href="/CustomerSupport" class="dept-card">
                            <div class="jb_types openings">02 Openings</div>
                            <img class="dept-card-icon" src="../assets/images/c2.png" al="note" />
                            <div class="clearfix"></div>
                            <h6>Customer Support </h6>
                            <p>Assist and troubleshoot with the app and service requirements</p>
                            <a href="/CustomerSupport" class="btn btn-primary">View Jobs</a>
                        </a>
                    </div>
                    <div class="col-sm-4">
                        <a href="/DevOpsEngineer" class="dept-card">
                            <div class="jb_types openings">02 Openings</div>
                            <img class="dept-card-icon" src="../assets/images/c2.png" al="note" />
                            <div class="clearfix"></div>
                            <h6>DevOpsEngineer</h6>
                            <p>DevOps engineers build, test and maintain infrastructure and tools so that software can be developed and released.</p>
                            <a href="/DevOpsEngineer" class="btn btn-primary">View Jobs</a>
                        </a>
                    </div>
                </div>
            </div>
        </section>
      
      <Footer/>

  </> 
  ); 
  } 
  export default Careers;