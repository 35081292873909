
import Footer from '../../components/Footer';
import Header from '../../components/Header';  
import Banner from './Banner'; 
import Services from './Services';
import FounderNews from './FounderNews';
import HomeBusinesses from './HomeBusinesses';
import HomeSustainability from './HomeSustainability';
import LatestNews from './LatestNews';
import WorkWithUs from './WorkWithUs';
import GroupWebsite from './GroupWebsite';
function Home() { 
	return( 
	<>
    <Header /> 
    <Banner/>
    <Services/> 
    <FounderNews/> 
    <HomeBusinesses/> 
    <HomeSustainability/> 
    <LatestNews/> 
    <GroupWebsite/> 
    <WorkWithUs/> 
    <Footer/>
    
        
  </> 
  ); 
  } 
  export default Home;