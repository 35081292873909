
function Services() { 
	return( 
	<>
        <section class="section aos-init aos-animate" data-aos="fade-up" data-aos-offset="600" data-aos-duration="1500" data-aos-once="true">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12"> 
                        <div class="section-title res-991-mb-0">
                            <div class="title-header">
                                <h2 class="heading font-regular aos-init aos-animate" data-aos="fade-up" data-aos-offset="100" data-aos-duration="650" data-aos-easing="ease-in-out">Pixalive Group works for <br/>common goal</h2>
                            </div>
                        </div> 
                    </div>
                    <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 ">
                        <div class="section-title res-991-mb-0">
                            <div class="title-header">
                                <p data-aos="fade-up" data-aos-offset="100" data-aos-duration="650" data-aos-easing="ease-in-out">Our mission is to become self-reliant & key market player in the 6s sectors in India</p>
                            </div>
                        </div> 
                    </div>
                </div>
                <div class="row row-equal-height ">
                    <div class="col-lg-4 col-md-6 col-sm-6 col-12 mb-20">
                        <div class="featured-icon-box style1 first-child">
                            <div class="featured-icon-thumnail">
                                <img src="./assets/images/social-media.png" loading="lazy" alt="Icon" class="rotate-img img-fluid" /></div>
                            <div class="featured-content">
                                <h3 class="featured-title-h3">
                                Social Media
                                </h3>
                                <p>Praesent faucibus nisl sit amet nulla pretium a sed purus.</p>
                                <div class="featured-bottom-btn">
                                <a href="services.html" class="btn btn-inline">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.922 4.5V11.8125C13.922 11.9244 13.8776 12.0317 13.7985 12.1108C13.7193 12.1899 13.612 12.2344 13.5002 12.2344C13.3883 12.2344 13.281 12.1899 13.2018 12.1108C13.1227 12.0317 13.0783 11.9244 13.0783 11.8125V5.51953L4.79547 13.7953C4.71715 13.8736 4.61092 13.9176 4.50015 13.9176C4.38939 13.9176 4.28316 13.8736 4.20484 13.7953C4.12652 13.717 4.08252 13.6108 4.08252 13.5C4.08252 13.3892 4.12652 13.283 4.20484 13.2047L12.4806 4.92188H6.18765C6.07577 4.92188 5.96846 4.87743 5.88934 4.79831C5.81023 4.71919 5.76578 4.61189 5.76578 4.5C5.76578 4.38811 5.81023 4.28081 5.88934 4.20169C5.96846 4.12257 6.07577 4.07813 6.18765 4.07812H13.5002C13.612 4.07813 13.7193 4.12257 13.7985 4.20169C13.8776 4.28081 13.922 4.38811 13.922 4.5Z" fill="currentColor"></path>
                                    </svg>
                                </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6 col-12 mb-20">
                        <div class="featured-icon-box style1 border-none">
                            <div class="featured-icon-thumnail">
                                <img src="./assets/images/saas.png" loading="lazy" alt="Icon" class="rotate-img img-fluid" /></div>
                            <div class="featured-content">
                                <h3 class="featured-title-h3">
                                SaaS
                                </h3>
                                <p>Praesent faucibus nisl sit amet nulla pretium a sed purus.</p>
                                <div class="featured-bottom-btn">
                                <a href="services.html" class="btn btn-inline">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.922 4.5V11.8125C13.922 11.9244 13.8776 12.0317 13.7985 12.1108C13.7193 12.1899 13.612 12.2344 13.5002 12.2344C13.3883 12.2344 13.281 12.1899 13.2018 12.1108C13.1227 12.0317 13.0783 11.9244 13.0783 11.8125V5.51953L4.79547 13.7953C4.71715 13.8736 4.61092 13.9176 4.50015 13.9176C4.38939 13.9176 4.28316 13.8736 4.20484 13.7953C4.12652 13.717 4.08252 13.6108 4.08252 13.5C4.08252 13.3892 4.12652 13.283 4.20484 13.2047L12.4806 4.92188H6.18765C6.07577 4.92188 5.96846 4.87743 5.88934 4.79831C5.81023 4.71919 5.76578 4.61189 5.76578 4.5C5.76578 4.38811 5.81023 4.28081 5.88934 4.20169C5.96846 4.12257 6.07577 4.07813 6.18765 4.07812H13.5002C13.612 4.07813 13.7193 4.12257 13.7985 4.20169C13.8776 4.28081 13.922 4.38811 13.922 4.5Z" fill="currentColor"></path>
                                    </svg>
                                </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6 col-12 mb-20">
                        <div class="featured-icon-box style1 res-991-pl-0">
                            <div class="featured-icon-thumnail">
                                <img src="./assets/images/services.png" loading="lazy" alt="Icon" class="rotate-img img-fluid" /></div>
                            <div class="featured-content">
                                <h3 class="featured-title-h3">
                                Services
                                </h3>
                                <p>Praesent faucibus nisl sit amet nulla pretium a sed purus.</p>
                                <div class="featured-bottom-btn">
                                <a href="services.html" class="btn btn-inline">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.922 4.5V11.8125C13.922 11.9244 13.8776 12.0317 13.7985 12.1108C13.7193 12.1899 13.612 12.2344 13.5002 12.2344C13.3883 12.2344 13.281 12.1899 13.2018 12.1108C13.1227 12.0317 13.0783 11.9244 13.0783 11.8125V5.51953L4.79547 13.7953C4.71715 13.8736 4.61092 13.9176 4.50015 13.9176C4.38939 13.9176 4.28316 13.8736 4.20484 13.7953C4.12652 13.717 4.08252 13.6108 4.08252 13.5C4.08252 13.3892 4.12652 13.283 4.20484 13.2047L12.4806 4.92188H6.18765C6.07577 4.92188 5.96846 4.87743 5.88934 4.79831C5.81023 4.71919 5.76578 4.61189 5.76578 4.5C5.76578 4.38811 5.81023 4.28081 5.88934 4.20169C5.96846 4.12257 6.07577 4.07813 6.18765 4.07812H13.5002C13.612 4.07813 13.7193 4.12257 13.7985 4.20169C13.8776 4.28081 13.922 4.38811 13.922 4.5Z" fill="currentColor"></path>
                                    </svg>
                                </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6 col-12 mb-20">
                        <div class="featured-icon-box style1 last-child">
                            <div class="featured-icon-thumnail">
                                <img src="./assets/images/startup.png" loading="lazy" alt="Icon" class="rotate-img img-fluid" />
                            </div>
                            <div class="featured-content">
                                <h3 class="featured-title-h3">
                                Startups
                                </h3>
                                <p>Praesent faucibus nisl sit amet nulla pretium a sed purus.</p>
                                <div class="featured-bottom-btn">
                                <a href="services.html" class="btn btn-inline">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.922 4.5V11.8125C13.922 11.9244 13.8776 12.0317 13.7985 12.1108C13.7193 12.1899 13.612 12.2344 13.5002 12.2344C13.3883 12.2344 13.281 12.1899 13.2018 12.1108C13.1227 12.0317 13.0783 11.9244 13.0783 11.8125V5.51953L4.79547 13.7953C4.71715 13.8736 4.61092 13.9176 4.50015 13.9176C4.38939 13.9176 4.28316 13.8736 4.20484 13.7953C4.12652 13.717 4.08252 13.6108 4.08252 13.5C4.08252 13.3892 4.12652 13.283 4.20484 13.2047L12.4806 4.92188H6.18765C6.07577 4.92188 5.96846 4.87743 5.88934 4.79831C5.81023 4.71919 5.76578 4.61189 5.76578 4.5C5.76578 4.38811 5.81023 4.28081 5.88934 4.20169C5.96846 4.12257 6.07577 4.07813 6.18765 4.07812H13.5002C13.612 4.07813 13.7193 4.12257 13.7985 4.20169C13.8776 4.28081 13.922 4.38811 13.922 4.5Z" fill="currentColor"></path>
                                    </svg>
                                </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6 col-12 mb-20">
                        <div class="featured-icon-box style1 last-child">
                            <div class="featured-icon-thumnail">
                                <img src="./assets/images/software-training.png" loading="lazy" alt="Icon" class="rotate-img img-fluid" />
                            </div>
                            <div class="featured-content">
                                <h3 class="featured-title-h3">
                                Software Training
                                </h3>
                                <p>Praesent faucibus nisl sit amet nulla pretium a sed purus.</p>
                                <div class="featured-bottom-btn">
                                <a href="services.html" class="btn btn-inline">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.922 4.5V11.8125C13.922 11.9244 13.8776 12.0317 13.7985 12.1108C13.7193 12.1899 13.612 12.2344 13.5002 12.2344C13.3883 12.2344 13.281 12.1899 13.2018 12.1108C13.1227 12.0317 13.0783 11.9244 13.0783 11.8125V5.51953L4.79547 13.7953C4.71715 13.8736 4.61092 13.9176 4.50015 13.9176C4.38939 13.9176 4.28316 13.8736 4.20484 13.7953C4.12652 13.717 4.08252 13.6108 4.08252 13.5C4.08252 13.3892 4.12652 13.283 4.20484 13.2047L12.4806 4.92188H6.18765C6.07577 4.92188 5.96846 4.87743 5.88934 4.79831C5.81023 4.71919 5.76578 4.61189 5.76578 4.5C5.76578 4.38811 5.81023 4.28081 5.88934 4.20169C5.96846 4.12257 6.07577 4.07813 6.18765 4.07812H13.5002C13.612 4.07813 13.7193 4.12257 13.7985 4.20169C13.8776 4.28081 13.922 4.38811 13.922 4.5Z" fill="currentColor"></path>
                                    </svg>
                                </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6 col-12 mb-20">
                        <div class="featured-icon-box style1 last-child">
                            <div class="featured-icon-thumnail">
                                <img src="./assets/images/sustainability.png" loading="lazy" alt="Icon" class="rotate-img img-fluid" />
                            </div>
                            <div class="featured-content">
                                <h3 class="featured-title-h3">
                                Sustainability
                                </h3>
                                <p>Praesent faucibus nisl sit amet nulla pretium a sed purus.</p>
                                <div class="featured-bottom-btn">
                                <a href="services.html" class="btn btn-inline">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.922 4.5V11.8125C13.922 11.9244 13.8776 12.0317 13.7985 12.1108C13.7193 12.1899 13.612 12.2344 13.5002 12.2344C13.3883 12.2344 13.281 12.1899 13.2018 12.1108C13.1227 12.0317 13.0783 11.9244 13.0783 11.8125V5.51953L4.79547 13.7953C4.71715 13.8736 4.61092 13.9176 4.50015 13.9176C4.38939 13.9176 4.28316 13.8736 4.20484 13.7953C4.12652 13.717 4.08252 13.6108 4.08252 13.5C4.08252 13.3892 4.12652 13.283 4.20484 13.2047L12.4806 4.92188H6.18765C6.07577 4.92188 5.96846 4.87743 5.88934 4.79831C5.81023 4.71919 5.76578 4.61189 5.76578 4.5C5.76578 4.38811 5.81023 4.28081 5.88934 4.20169C5.96846 4.12257 6.07577 4.07813 6.18765 4.07812H13.5002C13.612 4.07813 13.7193 4.12257 13.7985 4.20169C13.8776 4.28081 13.922 4.38811 13.922 4.5Z" fill="currentColor"></path>
                                    </svg>
                                </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </section>
        
  </> 
  ); 
  } 
  export default Services;