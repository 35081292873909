import Header from '../../components/Header';  
import Footer from '../../components/Footer';
function CustomerSupport() { 
	return( 
	<>  
    
      <Header /> 
      <div class="breadcrumb-area bg-gray bg-cover">
        <div class="container">
            <div class="breadcrumb-item">
                <div class="breadcrum-shape">
                    <img src="./assets/images/bg-shape-3.png" alt="shape" />
                </div>
                <div class="row">
                    <div class="col-lg-8">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li><a href="/Careers"><i class="fa fa-home"></i>Careers</a></li>
                                <li class="active">Job Description</li>
                            </ol>
                        </nav>
                        <h1>Job Description</h1>
                    </div>
                </div>
            </div>
        </div>
      </div>

      <article role="article" class="node node--type-kenexa-jobs node--view-mode-full clearfix" id="training-hiring-programs" >
        <div class="section-jobs-page">
            <div class="container">
            {/* <div class="page-title">
                <h2>Job Description</h2>
            </div> */}
            <div class="node__content clearfix">
                <h3 class="node__title jobs-title custom-text-uppercase">
                <span class="field field--name-title field--type-string field--label-hidden">Customer Support</span>
                </h3>
                <div class="main-content-body">
                <div class="left-info">
                    <h4 class="job-summary">Job Summary</h4>
                    <div class="row">
                    <div class="col-md-4 kenexa-inline-field jobs-location">
                        <div class="kenexa-inline-box"> 
                        <div class="kenexa-field-text">
                            <h6 class="custom-text-uppercase">Location:</h6>
                            <p> Bangalore </p>
                        </div>
                        </div>
                    </div>
                    <div class="col-md-4 kenexa-inline-field jobs-designation">
                        <div class="kenexa-inline-box"> 
                        <div class="kenexa-field-text">
                            <h6 class="custom-text-uppercase">Designation:</h6>
                            <p>Customer Relationship Manager</p>
                        </div>
                        </div>
                    </div>
                    <div class="col-md-4 kenexa-inline-field jobs-qualification">
                        <div class="kenexa-inline-box"> 
                        <div class="kenexa-field-text">
                            <div class="qualification">
                            <h6 class="custom-text-uppercase">Qualification:</h6>
                            </div>
                            <p>B.E, B.Tech </p>
                        </div>
                        </div>
                    </div>
                    <div class="col-md-4 kenexa-inline-field jobs-experience">
                        <div class="kenexa-inline-box"> 
                        <div class="kenexa-field-text">
                            <h6 class="custom-text-uppercase">Experience:</h6>
                            <p>2.5-3 Years </p>
                        </div>
                        </div>
                    </div>
                    <div class="col-md-4 kenexa-inline-field jobs-skills">
                        <div class="kenexa-inline-box"> 
                        <div class="kenexa-field-text">
                            <div class="skills">
                            <h6 class="custom-text-uppercase">Skills:</h6>
                            </div>
                            <p>Telecalling, Chat support </p>
                        </div>
                        </div>
                    </div>
                    <div class="col-md-4 kenexa-inline-field jobs-positions">
                        <div class="kenexa-inline-box"> 
                        <div class="kenexa-field-text">
                            <h6 class="custom-text-uppercase">No. of positions:</h6>
                            <p> 02 </p>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 jobs-description">
                    <div class="kenexa-inline-box d-block">
                        <div class="kenexa-inline-box-job d-flex"> 
                        <h6 class="custom-text-uppercase">Job description:</h6>
                        </div>
                        <div class="kenexa-field-text">
                        <div class="jobs-description">
                            <div class="description">
                            {/* <p> Role & responsibilities</p> */}
                            <ul>
                                <li>A customer service representative’s primary objective is to understand the customer’s problem and troubleshoot it with an optimal and effective solution.</li>
                                <li>Customer service processes focus on short-term customer goals and do not directly impact revenue and other long-term objectives. </li>
                                <li>A customer service team’s underlying focus is on problem solving in the short term.</li>
                                <li>Support roles generally fall under conflict resolution, responding to tickets, answering phone calls from struggling customers, and responding to all customer concerns.</li>
                                <li>Customer support is measured by factors like speed and quality of help received.</li>
                                <li>Some of the most important support metrics include average response time, first call resolution, customer satisfaction rating, and net promoter score.</li>


                            </ul>
                            <p></p>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div class="col-lg-12">
                    <div class="jobs-link mt-3">
                        <a class="long-button blue" href="/JobApply" rel="noopener">Apply</a>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </article>
      
      <Footer/>

  </> 
  ); 
  } 
  export default CustomerSupport;