import Header from '../../components/Header';  
import Footer from '../../components/Footer';
function Blogs() { 
	return( 
	<>  
    
      <Header /> 
      
      <div class="breadcrumb-area bg-gray bg-cover">
        <div class="container">
            <div class="breadcrumb-item">
                <div class="breadcrum-shape">
                    <img src="./assets/images/bg-shape-3.png" alt="shape" />
                </div>
                <div class="row">
                    <div class="col-lg-8">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li><a href="/"><i class="fa fa-home"></i> Home</a></li>
                                <li class="active">Blogs</li>
                            </ol>
                        </nav>
                        <h1>Blogs</h1>
                    </div>
                </div>
            </div>
        </div>
      </div>
         <div class="paragraph paragraph--type--case-studies-listings paragraph--view-mode--default page-section-default cloud-case-studies-wrap page-title-left page-title-new case-studies-listing-wrap section-hidden-remove">
          
            <div class="container">                       
                     <div class="section-header pb-lg-4 text-center">
                        <h2 class="heading font-regular" data-aos="fade-up" data-aos-offset="100" data-aos-duration="650" data-aos-easing="ease-in-out">Blogs</h2>
                        <div class="only-value-content-counter">
                           <p class="width-70" data-aos="fade-up" data-aos-offset="100" data-aos-duration="650" data-aos-easing="ease-in-out">
                           Pixalive businesses consist of both product-based and service-based offerings
                           </p>
                        </div>
                     </div>
                  </div>
            <div>   
               <div class="container">
               <div class="field field--name-field-case-studies-reference field--type-viewsreference field--label-visually_hidden">
                  
                  <div class="field__item">
                     <div class="views-element-container">
                        <div class="case-studies-without-slider view view-all-resources view-id-all_resources view-display-id-case_study_listings  " data-once="ajax-pager">
                           
                           <div class="view-content row">
                              <div class="col-lg-4 col-md-6 col-sm-6 views-row">
                                 <div class="views-field views-field-nothing">
                                    <a href="/TheRiseofPixalive" class="field-content">
                                       <div class="card case-studies-item">
                                          <div class="case-carousel-img" >
                                             <img class="img-fluid"  src="./assets/images/blog/blog-1.jpg" />
                                          </div>
                                          <div class="case-studies-carousel-text">
                                             <div class="case-study-header">
                                                <span class="tag-date"><a href="/TheRiseofPixalive">Social Media</a></span>
                                                <h3 class="card-heading-flex" >The Rise of Pixalive: Pioneering a New Era in India’s Social Media Landscape</h3>
                                             </div>
                                             <div class="case-studies-desc">
                                                <p>It was during this challenging time that Dr. Abdul Kalam, a visionary leader, and renowned figure, stepped in to provide crucial support...</p>
                                             </div>
                                             <a class="link-box" href="/TheRiseofPixalive">
                                                <span>Learn more</span>
                                                <i class="fa fa-long-arrow-right"></i>
                                             </a>
                                          </div>
                                       </div>
                                    </a>
                                 </div>
                              </div>
                              <div class="col-lg-4 col-md-6 col-sm-6 views-row">
                                 <div class="views-field views-field-nothing">
                                    <a href="/PixaliveBengaluruOffice" class="field-content">
                                       <div class="card case-studies-item">
                                          <div class="case-carousel-img" >
                                             <img class="img-fluid"  src="./assets/images/blog/pixalive-pooja.jpg" />
                                          </div>
                                          <div class="case-studies-carousel-text">
                                             <div class="case-study-header">
                                                <span class="tag-date"><a href="/PixaliveBengaluruOffice">Investing</a></span>
                                                <h3 class="card-heading-flex">Pixalive's Bengaluru Office: Transforming Vision Into Reality</h3>
                                             </div>
                                             <div class="case-studies-desc">
                                                <p> In today's fast-paced corporate world, where innovation and collaboration are the keys to success, a new office space can be a game-changer...</p>
                                             </div>
                                             <a class="link-box" href="PixaliveBengaluruOffice">
                                                <span>Learn more</span>
                                                <i class="fa fa-long-arrow-right"></i>
                                             </a>
                                          </div>
                                       </div>
                                    </a>
                                 </div>
                              </div>
                              <div class="col-lg-4 col-md-6 col-sm-6 views-row">
                                 <div class="views-field views-field-nothing">
                                    <a href="/WhatAreTheMostPopularApps" class="field-content">
                                       <div class="card case-studies-item">
                                          <div class="case-carousel-img" >
                                             <img class="img-fluid"  src="./assets/images/blog/blog-5.jpg" />
                                          </div>
                                          <div class="case-studies-carousel-text">
                                             <div class="case-study-header">
                                                <span class="tag-date"><a href="/WhatAreTheMostPopularApps">Social Made</a></span>
                                                <h3 class="card-heading-flex" >What are the 10 most popular apps?</h3>
                                             </div>
                                             <div class="case-studies-desc">
                                                <p>Now that we are into 2020 and must have come across these most popular apps and on the trend...</p>
                                             </div>
                                             <a class="link-box" href="/WhatAreTheMostPopularApps">
                                                <span>Learn more</span>
                                                <i class="fa fa-long-arrow-right"></i>
                                             </a>
                                          </div>
                                       </div>
                                    </a>
                                 </div>
                              </div>
                              <div class="col-lg-4 col-md-6 col-sm-6 views-row">
                                 <div class="views-field views-field-nothing">
                                    <a href="/HeloAppAlternativesinIndiaLikePixalive" class="field-content">
                                       <div class="card case-studies-item">
                                          <div class="case-carousel-img" >
                                             <img class="img-fluid"  src="./assets/images/blog/blog-3.jpg" />
                                          </div>
                                          <div class="case-studies-carousel-text">
                                             <div class="case-study-header">
                                                <span class="tag-date"><a href="/HeloAppAlternativesinIndiaLikePixalive">Social Media</a></span>
                                                <h3 class="card-heading-flex">Helo App Alternatives in India Like Pixalive, Roposo & ShareChat</h3>
                                             </div>
                                             <div class="case-studies-desc">
                                                <p>Pixalive, Roposo, and ShareChat have taken the Chinese Helo app’s market after its ban, along with several other popular Chinese apps...</p>
                                             </div>
                                             <a class="link-box" href="/HeloAppAlternativesinIndiaLikePixalive">
                                                <span>Learn more</span>
                                                <i class="fa fa-long-arrow-right"></i>
                                             </a>
                                          </div>
                                       </div>
                                    </a>
                                 </div>
                              </div>
                              <div class="col-lg-4 col-md-6 col-sm-6 views-row">
                                 <div class="views-field views-field-nothing">
                                    <a href="/PixaliveappisNowtrendinginIndia" class="field-content">
                                       <div class="card case-studies-item">
                                          <div class="case-carousel-img" >
                                             <img class="img-fluid"  src="./assets/images/blog/blog-4.jpg" />
                                          </div>
                                          <div class="case-studies-carousel-text">
                                             <div class="case-study-header">
                                                <span class="tag-date"><a href="/PixaliveappisNowtrendinginIndia">Social Media</a></span>
                                                <h3 class="card-heading-flex" >Pixalive app is Now trending in India!</h3>
                                             </div>
                                             <div class="case-studies-desc">
                                                <p>Ever since the ban of the Chinese app by The Ministry of Electronics and Information Technology, India, social media users have been in a turbulence...</p>
                                             </div>
                                             <a class="link-box" href="/PixaliveappisNowtrendinginIndia">
                                                <span>Learn more</span>
                                                <i class="fa fa-long-arrow-right"></i>
                                             </a>
                                          </div>
                                       </div>
                                    </a>
                                 </div>
                              </div> 


                           </div>
                           <div class="views-pager">
                              <nav aria-label="pagination-heading">
                                 <h4 id="pagination-heading" class="visually-hidden">Pagination</h4>
                                 <ul class="pagination js-pager__items">
                                    <li class="page-item active">
                                       <span class="page-link">1</span>
                                    </li>
                                    <li class="page-item ">
                                       <a href="?_wrapper_format=html&amp;page=1" title="" class="page-link">2</a>
                                    </li>
                                    <li class="page-item ">
                                       <a href="?_wrapper_format=html&amp;page=2" title="" class="page-link">3</a>
                                    </li>
                                    <li class="page-item ">
                                       <a href="?_wrapper_format=html&amp;page=3" title="" class="page-link">4</a>
                                    </li>
                                    <li class="page-item ">
                                       <a href="?_wrapper_format=html&amp;page=4" title="" class="page-link">5</a>
                                    </li>
                                    <li class="page-item ">
                                       <a href="?_wrapper_format=html&amp;page=5" title="" class="page-link">6</a>
                                    </li>
                                    <li class="page-item ">
                                       <a href="?_wrapper_format=html&amp;page=6" title="" class="page-link">7</a>
                                    </li>
                                    <li class="page-item ">
                                       <a href="?_wrapper_format=html&amp;page=7" title="" class="page-link">8</a>
                                    </li>
                                    <li class="page-item ">
                                       <a href="?_wrapper_format=html&amp;page=8" title="" class="page-link">9</a>
                                    </li>
                                    <li class="page-item" role="presentation"><span class="page-link">…</span></li>
                                    <li class="pager__item--next">
                                       <a href="?_wrapper_format=html&amp;page=1" title="Go to next page" rel="next" class="page-link">
                                       <span aria-hidden="true">Next</span>
                                       <span class="visually-hidden">Next page</span>
                                       </a>
                                    </li>
                                    <li class="page-item">
                                       <a href="?_wrapper_format=html&amp;page=10" title="Go to last page" class="page-link">
                                       <span aria-hidden="true">Last »</span>
                                       <span class="visually-hidden">Last page</span>
                                       </a>
                                    </li>
                                 </ul>
                              </nav>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div> 
      </div>
      
      <Footer/>

  </> 
  ); 
  } 
  export default Blogs;