import Header from '../../components/Header';  
import Footer from '../../components/Footer';
function MernStack() { 
	return( 
	<>  
    
      <Header /> 
      <div class="breadcrumb-area bg-gray bg-cover">
        <div class="container">
            <div class="breadcrumb-item">
                <div class="breadcrum-shape">
                    <img src="./assets/images/bg-shape-3.png" alt="shape" />
                </div>
                <div class="row">
                    <div class="col-lg-8">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li><a href="/Careers"><i class="fa fa-home"></i>Careers</a></li>
                                <li class="active">Job Description</li>
                            </ol>
                        </nav>
                        <h1>Job Description</h1>
                    </div>
                </div>
            </div>
        </div>
      </div>

      <article role="article" class="node node--type-kenexa-jobs node--view-mode-full clearfix" id="training-hiring-programs" >
        <div class="section-jobs-page">
            <div class="container">
            {/* <div class="page-title">
                <h2>Job Description</h2>
            </div> */}
            <div class="node__content clearfix">
                <h3 class="node__title jobs-title custom-text-uppercase">
                <span class="field field--name-title field--type-string field--label-hidden">MERN Stack Developer</span>
                </h3>
                <div class="main-content-body">
                <div class="left-info">
                    <h4 class="job-summary">Job Summary</h4>
                    <div class="row">
                    <div class="col-md-4 kenexa-inline-field jobs-location">
                        <div class="kenexa-inline-box"> 
                        <div class="kenexa-field-text">
                            <h6 class="custom-text-uppercase">Location:</h6>
                            <p> Bangalore </p>
                        </div>
                        </div>
                    </div>
                    <div class="col-md-4 kenexa-inline-field jobs-designation">
                        <div class="kenexa-inline-box"> 
                        <div class="kenexa-field-text">
                            <h6 class="custom-text-uppercase">Designation:</h6>
                            <p>Software Developer</p>
                        </div>
                        </div>
                    </div>
                    <div class="col-md-4 kenexa-inline-field jobs-qualification">
                        <div class="kenexa-inline-box"> 
                        <div class="kenexa-field-text">
                            <div class="qualification">
                            <h6 class="custom-text-uppercase">Qualification:</h6>
                            </div>
                            <p>B.E, B.Tech </p>
                        </div>
                        </div>
                    </div>
                    <div class="col-md-4 kenexa-inline-field jobs-experience">
                        <div class="kenexa-inline-box"> 
                        <div class="kenexa-field-text">
                            <h6 class="custom-text-uppercase">Experience:</h6>
                            <p>2.5-3 Years </p>
                        </div>
                        </div>
                    </div>
                    <div class="col-md-4 kenexa-inline-field jobs-skills">
                        <div class="kenexa-inline-box"> 
                        <div class="kenexa-field-text">
                            <div class="skills">
                            <h6 class="custom-text-uppercase">Skills:</h6>
                            </div>
                            <p>MongoDB, Express js, AngularJS, Node js</p>
                        </div>
                        </div>
                    </div>
                    <div class="col-md-4 kenexa-inline-field jobs-positions">
                        <div class="kenexa-inline-box"> 
                        <div class="kenexa-field-text">
                            <h6 class="custom-text-uppercase">No. of positions:</h6>
                            <p> 05 </p>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 jobs-description">
                    <div class="kenexa-inline-box d-block">
                        <div class="kenexa-inline-box-job d-flex"> 
                        <h6 class="custom-text-uppercase">Job description:</h6>
                        </div>
                        <div class="kenexa-field-text">
                        <div class="jobs-description">
                            <div class="description">
                            {/* <p> Role & responsibilities</p> */}
                            <ul> 
                                <li>Proven experience as a MERN Stack Developer with a strong portfolio showcasing web application projects. </li> 
                                <li>Deep Knowledge in Frameworks like React. </li> 
                                <li>Solid understanding of the complete MERN stack and its individual components (MongoDB, Express.js, React, Node.js). </li> 
                                <li>Proficiency in building responsive and interactive user interfaces using React and related libraries. </li> 
                                <li>Familiarity with RESTful API development and integration. </li> 
                                <li>Strong understanding of database design and management, particularly MongoDB. </li> 
                                <li>Proficiency in version control systems, particularly Git. </li> 
                                <li>Excellent problem-solving skills and the ability to work collaboratively in a team environment. </li> 
                                <li>Good communication skills to convey technical concepts effectively. </li> 
                                <li>A proactive attitude towards learning new technologies and tools. </li> 

                            </ul>
                            <p></p>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div class="col-lg-12">
                    <div class="jobs-link mt-3">
                        <a class="long-button blue" href="/JobApply" rel="noopener">Apply</a>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </article>
      
      <Footer/>

  </> 
  ); 
  } 
  export default MernStack;