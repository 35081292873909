import React from 'react';

function  Header () {
  return (
  <>
  
  
   <header class="main-header">
         <div class="container">
            <div class="wrap ttm-header-style-03">
               <div class="logo">
                  <a href="/">
                     <img src="./assets/images/logo-light.svg" alt="logo" />
                  </a>
               </div>
               <div class="hamburger">
                  <ul>
                     <li></li>
                     <li></li>
                     <li></li>
                  </ul>
               </div>
               <div>
                  <div class="ttm-topbar-wrapper clearfix">
                     <div class="ttm-topbar-content">
                           <div class="topbar-right text-right">
                              <ul class="top-contact">
                                 <li><i class="fa fa-map-marker"></i>Third floor, No 35/2 Konappana Agrahara, Hosur Rd, 2nd phase, Electronic City, Karnataka 560100</li>
                                 <li><i class="fa fa-envelope-o"></i><a href="mailto:contact@pixalive.me">contact@pixalive.me</a></li>
                                 
                              </ul>
                              <div class="ttm-social-links-wrapper list-inline">
                                 <ul class="social-icons">
                                    <li><a target='_blank' href="https://play.google.com/store/apps/details?id=com.pixalive"><img src="./assets/images/pixalive.svg" alt="logo" /></a></li>
                                    <li><a target='_blank' href="https://twitter.com/PixaliveG">
                                       <svg width="13" height="14" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490.17 443.54" fill="#757576;">
                                             <g id="_1d7FH8.tif" data-name="1d7FH8.tif">
                                             <g>
                                                   <path d="m9.59,443.52c58.76-67.2,117.21-134.06,175.69-200.93C123.55,161.83,61.87,81.12,0,.18.8.12,1.36.04,1.91.04c50.51,0,101.02,0,151.53-.04,1.32,0,2.04.47,2.79,1.47,10.83,14.46,21.67,28.9,32.57,43.31,15.44,20.42,30.94,40.8,46.4,61.21,8.59,11.33,17.16,22.69,25.74,34.03.4.53.82,1.04,1.32,1.69,3.36-3.84,6.63-7.57,9.89-11.31,9.39-10.74,18.78-21.48,28.16-32.22,28.11-32.16,56.23-64.32,84.31-96.5C385.62.53,386.6,0,388.18,0c23.61.06,47.22.04,70.82.04h2.32c-54.91,62.77-109.55,125.23-164.3,187.82,64.35,85.14,128.67,170.22,193.15,255.52-.74.05-1.29.13-1.84.13-49.23,0-98.47,0-147.7.03-1.19,0-1.92-.32-2.66-1.29-26.6-34.85-53.23-69.68-79.86-104.51-12.07-15.78-24.13-31.57-36.2-47.35-.44-.58-.89-1.16-1.46-1.89-4.83,5.52-9.56,10.91-14.28,16.3-16.66,19.03-33.31,38.07-49.97,57.1-20.76,23.72-41.52,47.45-62.28,71.17-2.74,3.13-5.45,6.29-8.24,9.37-.47.52-1.29,1.02-1.96,1.02-24.55.05-49.1.04-74.15.04Zm391.34-44.95c.11-.01.23-.03.34-.04l-.25-.39c-.33-.5-.64-1.02-1-1.49-13.15-17.4-26.3-34.8-39.46-52.2-19.27-25.48-38.54-50.96-57.8-76.45-15.43-20.4-30.85-40.8-46.27-61.2-15.79-20.88-31.57-41.78-47.37-62.65-14.68-19.39-29.42-38.73-44.09-58.13-10.85-14.34-21.64-28.74-32.45-43.11-.15-.08-.31-.16-.46-.24h-44.37c.58.82.95,1.36,1.35,1.88,8.76,11.46,17.52,22.93,26.28,34.39,15.38,20.11,30.76,40.22,46.14,60.33,17.09,22.35,34.18,44.71,51.27,67.06,15.33,20.05,30.66,40.1,45.98,60.15,13.62,17.82,27.24,35.63,40.86,53.45,19.67,25.73,39.34,51.46,59.04,77.17.53.7,1.53,1.48,2.31,1.48,13.31.07,26.63.03,39.94.01Z"></path>
                                                   <path class="cls-1" d="m319.54,270.1c-.19-.1-.38-.2-.57-.3.19.1.38.2.57.3Z"></path>
                                             </g>
                                             </g>
                                          </svg>
                                    </a></li>
                                    <li><a target='_blank' href="https://www.youtube.com/@PixaliveGroup">
                                             <svg width="15" height="19" viewBox="0 0 27 19" fill="#000">
                                             <path  d="M25.8286 3.03876C25.532 1.94099 24.658 1.07659 23.5481 0.783235C21.5361 0.25 13.4694 0.25 13.4694 0.25C13.4694 0.25 5.40274 0.25 3.39077 0.783235C2.28106 1.07659 1.40685 1.94099 1.11019 3.03876C0.571289 5.02839 0.571289 9.17966 0.571289 9.17966C0.571289 9.17966 0.571289 13.3309 1.11019 15.3204C1.40685 16.4181 2.28106 17.2827 3.39077 17.5761C5.40274 18.1091 13.4694 18.1091 13.4694 18.1091C13.4694 18.1091 21.5361 18.1091 23.5481 17.5761C24.658 17.2827 25.532 16.4181 25.8286 15.3204C26.3678 13.3309 26.3678 9.17966 26.3678 9.17966C26.3678 9.17966 26.3678 5.02839 25.8286 3.03876Z" />
                                             <path  fill="#fff" d="M11.4854 13.1483L18.4306 9.17963L11.4854 5.21094L11.4854 13.1483Z" id="bg" />
                                          </svg></a></li>

                                    <li><a target='_blank' href="https://www.linkedin.com/in/pixalive-group-3b6b65294/"><i class="fa fa-linkedin"></i></a></li>
                                 </ul>
                              </div>
                           </div>
                     </div>
                  </div>
               <nav class="main-nav">
                  
                  <ul>
                     <li class="has-child" itemprop="name">
                        <a href="/AboutUs">Our Company</a>    
                        <div class="mega-menu">
                           <div class="wrap container">
                              <div class="center-div">
                                 <div class="sub-nav-wrap">
                                    <ul>
                                       <li><a href="/AboutUs">About Us</a></li>
                                       <li><a href="#">Vision</a></li>
                                       <li><a href="#">Values</a></li>
                                       <li><a href="#">Corporate Team</a></li>  
                                    </ul>
                                 </div>                                 
                              </div>
                           </div>
                        </div>                              
                     </li>
                     <li class="has-child" itemprop="name">
                        <a itemprop="url" href="/Businesses">Businesses</a>
                        <div class="mega-menu">
                           <div class="wrap container">
                              <div class="center-div">
                                 <div class="sub-nav-wrap">
                                    <ul>
                                       <li><a href="#">Pixalive Platforms</a></li>
                                       <li><a href="#">Pixalive Works</a></li>
                                       <li><a href="#">Pixalive Ventures</a></li>
                                       <li><a href="#">Pixalive Consultancy Services</a></li> 
                                       <li><a href="#">Pixalive Labs</a></li>
                                       <li><a href="#">Pixalive Foundation</a></li>
                                    </ul>
                                 </div>
                                 
                              </div>
                           </div>
                        </div>
                     </li>
                     <li class="has-child">
                        <a href="/Investors">Investors</a> 
                     </li>
                     <li class="has-child">
                        <a href="/Careers">Careers</a> 
                     </li>
                     <li class="has-child" itemprop="name">
                        <a itemprop="url" href="/Sustainability">Sustainability</a>
                        <div class="mega-menu">
                           <div class="wrap container">
                              <div class="center-div">
                                 <div class="sub-nav-wrap">
                                    <ul>
                                       <li><a href="#Environmental">Environment</a></li>
                                       <li><a href="#Education">Education</a></li> 
                                    </ul>
                                 </div>                                          
                                 </div>
                           </div>
                        </div>
                     </li>
                     <li>
                        <a href="/Blogs">Blogs</a>
                     </li>
                     <li>
                        <a href="/ContactUs">Contact Us</a>
                     </li> 
                  </ul>
               </nav>
            </div>
         </div>
      </div>
   </header>


  </>
);
}

export default Header;