
function GroupWebsite() { 
	return( 
	<>  
    
    <section class="section sponsore-logo sub-bg">
      <div class="container">
          <div class="section-header text-center p-0">
            <h2 class="heading font-regular aos-init aos-animate" data-aos="fade-up" data-aos-offset="100" data-aos-duration="650" data-aos-easing="ease-in-out">Our Group Websites</h2>
            <p data-aos="fade-up" data-aos-offset="100" data-aos-duration="650" data-aos-easing="ease-in-out" class="aos-init aos-animate"> Bringing our vision to life</p>
          </div>

          <div class="col-md-12 col-sm-12"> 
            <ul class="customers_logo clients-wrapper aos-init aos-animate" data-aos="fade-up" data-aos-offset="100" data-aos-duration="650" data-aos-easing="ease-in-out">            
              
              <li class="logo-item img-responsives">   
                <a href="https://pixaliveplatforms.com/" target="_blank">                    
                  <img src="./assets/images/division-logo/pixlive-platforms.png" alt=""  />
                </a>     
              </li>
              {/* <li class="logo-item img-responsives">                            
                <img src="./assets/images/division-logo/pixalive.png" alt=""  />
              </li> */}
              <li class="logo-item img-responsives">    
                <a href="https://pixaliveservices.com/" target="_blank">                                            
                  <img src="./assets/images/division-logo/pcs.png" alt=""  />
                </a>     
              </li>
              <li class="logo-item img-responsives">     
                <a href="https://pixaliveventures.com/" target="_blank">                                           
                  <img src="./assets/images/division-logo/pixaliveventures.png" alt=""  />
                </a>     
              </li>
              <li class="logo-item img-responsives">   
                <a href="https://pixalivelabs.com/" target="_blank">                                            
                  <img src="./assets/images/division-logo/pixlive-labs.png" alt=""  />
                </a>     
              </li>
             </ul>
          </div>
         
      </div>
    </section>
             
  </> 
  ); 
  } 
  export default GroupWebsite;