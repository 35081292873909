import Header from '../../components/Header';  
import Footer from '../../components/Footer';
function ContactUs() { 
	return( 
	<>  
    
      <Header /> 
      <div class="breadcrumb-area bg-gray bg-cover">
        <div class="container">
            <div class="breadcrumb-item">
                <div class="breadcrum-shape">
                    <img src="./assets/images/bg-shape-3.png" alt="shape" />
                </div>
                <div class="row">
                    <div class="col-lg-8">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li><a href="/"><i class="fa fa-home"></i> Home</a></li>
                                <li class="active">Contact Us</li>
                            </ol>
                        </nav>
                        <h1>Contact Us</h1>
                    </div>
                </div>
            </div>
        </div>
      </div>

      <section class="contact-area contact-page overflow-hidden default-padding">
        <div class="container">
            <div class="row"> 
                <div class="col-tact-stye-one col-lg-5 pr-50 pr-md-15 pr-xs-15">
                    <div class="contact-style-one-info">
                        <ul class="contact-address bg-dark text-light">
                            <li>
                                <a class="phone-link" href="tel:+91 87785 84566">+91 87785 84566</a>
                            </li>                            
                            <li>
                                <div class="info">
                                    <h4>Official Email</h4>
                                    <a href="mailto:contact@pixalive.me">contact@pixalive.me</a>
                                </div>
                            </li>
                            <li>
                                <div class="info">
                                    <h4>Location</h4>
                                    <p>
                                    Third floor, No 35/2 Konappana Agrahara, Hosur Rd, 2nd phase, Electronic City, Karnataka 560100
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div class="info">
                                    <ul class="social-link">
                                        <li>
                                            <a class="facebook" href="#" target="_blank"><i class="fa fa-facebook-f"></i></a>
                                        </li>
                                        <li>
                                            <a class="twitter" href="#" target="_blank"><i class="fa fa-twitter"></i></a>
                                        </li>
                                        <li>
                                            <a class="linkedin" href="#" target="_blank"><i class="fa fa-linkedin"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-tact-stye-one col-lg-7 pl-60 pl-md-15 pl-xs-15 mt-md-50 mt-xs-50">
                    <div class="contact-form-style-one section-header">
                        <h4 class="sub-title-1 aos-init aos-animate" data-aos="fade-up" data-aos-offset="100" data-aos-duration="650" data-aos-easing="ease-in-out">Have Questions?</h4> 
                        <h2 class="heading font-regular aos-init aos-animate" data-aos="fade-up" data-aos-offset="100" data-aos-duration="650" data-aos-easing="ease-in-out">Send us a Message</h2>
                        <form action="assets/mail/contact.php" method="POST" class="contact-form contact-form mt-30">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <input class="form-control" id="name" name="name" placeholder="Name" type="text"/>
                                        <span class="alert-error"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input class="form-control" id="email" name="email" placeholder="Email" type="email"/>
                                        <span class="alert-error"></span>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input class="form-control" id="phone" name="phone" placeholder="Phone" type="text"/>
                                        <span class="alert-error"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group comments">
                                        <textarea class="form-control" id="comments" name="comments" placeholder="Tell Us About Project "></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <button type="submit" name="submit" id="submit">
                                        <i class="fa fa-paper-plane"></i> Get in Touch
                                    </button>
                                </div>
                            </div> 
                            <div class="col-lg-12 alert-notification">
                                <div id="message" class="alert-msg"></div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </div>
        
    </section>
    <section>
      <div id="google_map" class="google_map">
         <div class="map_container">
            <div id="map">
               <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15559.671290268201!2d77.6706383!3d12.8485841!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae6d95c77b9285%3A0x580316a77880fa5!2sPixalive!5e0!3m2!1sen!2sin!4v1704900918681!5m2!1sen!2sin" height="500"></iframe>
            </div>
         </div>
      </div>
    </section>
      
      <Footer/>

  </> 
  ); 
  } 
  export default ContactUs;