
function LatestNews() { 
	return( 
	<>  
    
      <section class="section  latest-stories home-stories">
         <div class="section-header text-center">
            <h2 class="heading font-regular" data-aos="fade-up" data-aos-offset="100" data-aos-duration="650" data-aos-easing="ease-in-out">Latest News</h2>
         </div>
         <div class="container" data-aos="fade-up" data-aos-offset="100" data-aos-duration="650" data-aos-easing="ease-in-out">
            <div class="flickity-slider-mobile-wrap grid-layout" >
               <div class="slides">
                  <div class="wrap">
                     <div class="image-wrap">
                        <img src="./assets/images/news/1.jpg" alt  class="thumb-img img-fluid" />
                     </div>
                     <div class="content-wrap">
                        <div class="category">Social Media</div>
                        <div class="text">How Pixalive, India’s own social media app, came into play</div>
                        <a target="_bank" href="https://www.thehindu.com/sci-tech/technology/how-pixalive-indias-own-social-media-app-came-into-play/article25888719.ece" class="btn btn-link">Read more</a>
                        <div class="date">
                           <time datetime="00Z">January 02, 2019 </time>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="slides">
                  <div class="wrap">
                     <div class="image-wrap">
                        <img src="./assets/images/news/3.jpg" alt  class="thumb-img img-fluid" />
                     </div>
                     <div class="content-wrap">
                        <div class="category">SOCIAL MEDIA</div>
                        <div class="text">Made in India social networking app raises 500K dollar in seed funding</div>
                        <a target="_bank" href="https://www.dtnext.in/business/2019/08/08/made-in-india-social-networking-app-raises-500k-dollar-in-seed-funding" class="btn btn-link">Read more</a>
                        <div class="date">
                           <time datetime="00Z">October 06, 2023</time>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="slides">
                  <div class="wrap">
                     <div class="image-wrap">
                        <picture>                                     
                           <img src="./assets/images/last.webp" alt  class="thumb-img img-fluid" />
                        </picture>
                     </div>
                     <div class="content-wrap">
                        <div class="category">SOCIAL MEDIA</div>
                        <div class="text">Pixalive Announces Actress Pranitha Subhash as Brand Ambassador</div>
                        <a target="_bank" href="https://www.cinemainbox.com/new-cinemadetail/pixalive-announces-actress-pranitha-subhash-as-brand-ambassador-5424.html" class="btn btn-link">Read more</a>
                        <div class="date">
                           <time datetime="00Z">November 09, 2018</time>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
             
  </> 
  ); 
  } 
  export default LatestNews;