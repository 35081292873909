
function HomeSustainability() { 
	return( 
	<>   
      <section class="mer-last-slider grey-bg section">
        <div class="container">
          <div class="section-header pb-lg-4">
              <h2 class="heading font-regular" data-aos="fade-up" data-aos-offset="100" data-aos-duration="650" data-aos-easing="ease-in-out">Sustainability</h2>
              <div class="only-value-content-counter">
                <p class="width-70" data-aos="fade-up" data-aos-offset="100" data-aos-duration="650" data-aos-easing="ease-in-out">
                To empower individuals and nations through innovative technology solutions that enhance education, promote sustainable living, and foster global connectivity, ultimately creating a brighter and more inclusive future for all.
                </p>
              </div>
          </div>
          <div class="swiper carousel  mer-last-slider carousel global-swiper-slider" data-aos="fade-up" data-aos-offset="100" data-aos-duration="650" data-aos-easing="ease-in-out">      
              <div class="flickity-button flickity-prev-next-button previous swiper-button-prev swiper-button-prev-1"></div>
              <div class="flickity-button flickity-prev-next-button next swiper-button-next swiper-button-next-1"></div>
              <div class="swiper-wrapper "> 
                <div class="swiper-slide carousel-cell">
                  <div class="img-container-mer-last-slider col-md-7 col-12 pe-md-0">
                      <picture>
                        <img src="./assets/images/environment.png" alt />
                      </picture>
                  </div>
                  <div class="text-conatiner-mer-last-slider col-md-5 col-12 textButtonLink">
                      <h3>Environment</h3> 
                      <h5>Planting Trees, Reducing Emissions, Saving Tomorrow</h5>
                      <p>
                      Protecting the environment is our responsibility, especially in a world with rapid carbon emissions. While we may not completely stop emissions, we can reduce them by planting and caring for trees.
                      </p>
                      <a href="/Sustainability" class="btn btn-link">Read more</a>
                  </div>
                </div>
                <div class="swiper-slide carousel-cell">
                  <div class="img-container-mer-last-slider col-md-7 col-12 pe-md-0">
                  <picture>
                        <img src="./assets/images/education.png" alt />
                      </picture>
                  </div>
                  <div class="text-conatiner-mer-last-slider col-md-5 col-12  textButtonLink">
                      <h3>Education</h3>
                      <h5>Empowering Rural Women, Coding Dreams, Changing Lives</h5>
                      <p>
                        Pixalive is empowering rural women through free programming training, giving them job opportunities, and helping them achieve their dreams. 
                      </p>
                      <a href="/Sustainability" class="btn btn-link">Read more</a>
                  </div>
                </div>
            
              </div>
                
               {/* <div class="swiper-pagination"></div> */}
              
          </div>
        </div>
    </section> 
             
  </> 
  ); 
  } 
  export default HomeSustainability;