import Header from '../../components/Header';  
import Footer from '../../components/Footer';
function Investors() { 
	return( 
	<>  
    
      <Header /> 
      <div class="breadcrumb-area bg-gray bg-cover">
        <div class="container">
            <div class="breadcrumb-item">
                <div class="breadcrum-shape">
                    <img src="./assets/images/bg-shape-3.png" alt="shape" />
                </div>
                <div class="row">
                    <div class="col-lg-8">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li><a href="/"><i class="fa fa-home"></i> Home</a></li>
                                <li class="active">Investors</li>
                            </ol>
                        </nav>
                        <h1>Investors</h1>
                    </div>
                </div>
            </div>
        </div>
      </div>
        <section class="section supercharging-highlighted-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 about-flex">
                        <div class="supercharging-highlight-infobox">
                            <h2>Welcome Investors!</h2>
                            <p>We welcome passionate individuals, angel investors, venture capitalists, and organizations to join us in investing in our company and becoming integral to our journey and growth. We are thrilled to have you on board as part of our exciting journey towards success.</p>
                            {/* <div class="supercharging-highlight-list">
                                <div class="highlight-list-inner">                
                                    <div class="highlight-list-item">
                                        <div class="highlight-list-icon">
                                            <img alt="Enriching job experiences" class="img-fluid" src="../images/components/supercharging-highligh-icon-2.svg" />
                                        </div>
                                        <div class="highlight-list-text">
                                            <p>Financial Information and live stock data of the companies</p>
                                        </div>
                                    </div>
                                    <div class="highlight-list-item">
                                        <div class="highlight-list-icon">
                                            <img alt="An employment that you can trust in" class="img-fluid" src="../images/components/supercharging-highligh-icon-3.svg" />
                                        </div>
                                        <div class="highlight-list-text">
                                            <p>An employment that you can trust in</p>
                                        </div>
                                    </div>                    
                                </div> 
                            </div> */}
                        </div>
                    </div>
                    <div class="col-lg-6 about-flex">
                        <img src="assets/images/investors.jpg" alt="Image Not Found" />
                    </div>
                </div>
            </div>
        </section>
        <section class="section bg-gray three-column-white-card-wrap supercharging-highlighted-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-6 three-column-white-card-column">
                        <div class="three-column-white-card-item">
                            <div class="three-column-white-card-content">
                                <h4>Why Pixalive Group?</h4>
                                <p>Pixalive Group aspires to be a global leader in sectors that improve lives and benefit nations, including Social Media, Services, Startups, and SaaS, through value-driven endeavours. Your investment plays a pivotal role in turning this vision into reality, and we are wholeheartedly committed to achieving it.</p>
                            </div> 
                        </div>
                    </div>  
                    <div class="col-lg-4 col-md-6 three-column-white-card-column">
                        <div class="three-column-white-card-item">
                            <div class="three-column-white-card-content">
                                <h4>Transparency & Communication</h4>
                                <p>We believe in transparent and effective communication. As an investor, you will receive regular updates on our financial performance, strategic plans, and company updates. We want you to stay informed and engaged in our progress.</p>
                            </div> 
                        </div>
                    </div>  
                    <div class="col-lg-4 col-md-6 three-column-white-card-column">
                        <div class="three-column-white-card-item">
                            <div class="three-column-white-card-content">
                                <h4>Engage & Connect</h4>
                                <p>Your insights and feedback are extremely important to us. We invite you to participate in investor meetings, webinars, and Q&A sessions. We cherish your perspective, knowing that constructive conversations pave the way for our mutual success. Your active involvement is vital to our progress.</p>
                            </div> 
                        </div>
                    </div>  
                    <div class="col-lg-4 col-md-6 three-column-white-card-column">
                        <div class="three-column-white-card-item">
                            <div class="three-column-white-card-content">
                                <h4>Your Investment, Our Future</h4>
                                <p>Your investment in Pixalive Group signifies your trust in our potential. Together, we are set to achieve great things and provide value. We are dedicated to keeping our promises and ensuring your investment is valuable.</p>
                            </div> 
                        </div>
                    </div> 
                    <div class="col-xl-6 offset-xl-1 col-lg-7 offset-lg-1">
                        
                        <div class="pricing-style-one active">
                            <div class="left">
                                <h4>Contact Us</h4>
                                <span>If you have any questions or need assistance, please feel free to reach out to our Investor Relations team at <a>investor@pixalivegroup.com. </a> </span> 
                                <span>We are here to support you in every step of your journey with us.</span>
                            </div> 
                        </div>
                    </div>
                     
                </div> 

            </div> 
        </section>  
        <section class="office-networks section">
         <div class="container">
            <div class="row">
                <div class="supercharging-highlight-infobox">
                    <h2>Contact Us</h2>
                </div>
                  <div class="col-md-6"> 
                    <div class="panel"> 
                        <div class="text-panel">
                            <h5> Pixalive Platforms</h5>
                            <h6>Join us in the dynamic world of Social Media.</h6>    
                            {/* <a href="#" class="btn btn-primary stretched-link">Invest Now</a>                    */}
                        </div>
                    </div> 
                    <div class="panel"> 
                        <div class="text-panel">
                            <h5>Pixalive Labs</h5>
                            <h6>Invest in talents, change their lifestyle.</h6>   
                            {/* <a href="#" class="btn btn-primary stretched-link">Invest Now</a>                      */}
                        </div>
                    </div> 
                    <div class="panel"> 
                        <div class="text-panel">
                            <h5>Pixalive Works</h5>
                            <h6>Explore opportunities in our Software as a Service (SaaS) division.</h6>  
                            {/* <a href="#" class="btn btn-primary stretched-link">Invest Now</a>                       */}
                        </div>
                    </div> 
                    <div class="panel"> 
                        <div class="text-panel">
                            <h5>Pixalive Ventures</h5>
                            <h6>Dive into the exciting realm of Startups.</h6>    
                            {/* <a href="#" class="btn btn-primary stretched-link">Invest Now</a>                     */}
                        </div>
                    </div> 
                    <div class="panel"> 
                        <div class="text-panel">
                            <h5>Pixalive Consultancy Services</h5>
                            <h6>Invest in our IT Services segment.</h6>                      
                        </div>
                    </div>
                </div>
                <div class="col-tact-stye-one col-lg-6">
                    <div class="contact-form-style-one contact-form-style-one-1 section-header">
                        <h4 class="sub-title-1 aos-init aos-animate" data-aos="fade-up" data-aos-offset="100" data-aos-duration="650" data-aos-easing="ease-in-out">Join Us</h4> 
                        <h2 class="heading font-regular aos-init aos-animate" data-aos="fade-up" data-aos-offset="100" data-aos-duration="650" data-aos-easing="ease-in-out">Invest in our Divisions</h2>
                        <form action="#" method="POST" class="contact-form contact-form mt-30">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <input class="form-control" id="name" name="Name" placeholder="Name *" type="text"/>
                                        <span class="alert-error"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input class="form-control" id="phone" name="phone" placeholder="Phone *" type="text"/>
                                        <span class="alert-error"></span>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input class="form-control" id="email" name="email" placeholder="Email *" type="email"/>
                                        <span class="alert-error"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input class="form-control" id="City" name="City" placeholder="City *" type="text"/>
                                        <span class="alert-error"></span>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input class="form-control" id="Pincode" name="Pincode" placeholder="Pincode" type="text"/>
                                        <span class="alert-error"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                    <select class="form-control form-control-1">
                                        <option>Divisions *</option>
                                        <option>Pixalive Platforms</option>
                                        <option>Pixalive Labs</option> 
                                        <option>Pixalive Works</option> 
                                        <option>Pixalive Ventures</option> 
                                        <option>Pixalive Consultancy Services</option>  
                                    </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group comments">
                                        <textarea class="form-control" id="comments" name="comments" placeholder="Message"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <button type="submit" name="submit" id="submit">
                                        Submit
                                    </button>
                                </div>
                            </div> 
                            <div class="col-lg-12 alert-notification">
                                <div id="message" class="alert-msg"></div>
                            </div>
                        </form>
                    </div>
                </div>
                 
            </div> 
          </div> 
        </section> 
                 
      
      <Footer/>

  </> 
  ); 
  } 
  export default Investors;