
function WorkWithUs() { 
	return( 
	<>  
    
    <section class="section work-with-us">
      <div class="section-header text-center">
          <h2 class="heading font-regular" data-aos="fade-up" data-aos-offset="100" data-aos-duration="650" data-aos-easing="ease-in-out">Work with us</h2>
      </div>
      <div class="position-relative" data-aos="fade-up" data-aos-offset="100" data-aos-duration="650" data-aos-easing="ease-in-out">
          <div class="container">
            <div class="row">
                <div class="col-md-4 slides">
                  <div class="wrap">
                      <div class="content-wrap">
                        <div class="section-header">
                            <h3 class="heading-sub">Join as an Investor</h3>
                            <p>Invest and grow with us.</p>
                            <a href="#" class="btn btn-primary stretched-link">Learn more</a>
                        </div>
                      </div>
                  </div>
                </div>
                <div class="col-md-4 slides">
                  <div class="wrap">
                      <div class="content-wrap">
                        <div class="section-header">
                            <h3 class="heading-sub">Join as an Employee</h3>
                            <p>Travel and achieve with us.</p>
                            <a href="#" class="btn btn-primary stretched-link">Learn more</a>
                        </div>
                      </div>
                  </div>
                </div>
                <div class="col-md-4 slides">
                  <div class="wrap">
                      <div class="content-wrap">
                        <div class="section-header">
                            <h3 class="heading-sub">Join as a Volunteer</h3>
                            <p>Join hands to bring about change in society.</p>
                            <a href="#" class="btn btn-primary stretched-link">Learn more</a>
                        </div>
                      </div>
                  </div>
                </div>
            </div>
          </div>
      </div>
    </section>
             
  </> 
  ); 
  } 
  export default WorkWithUs;