
function FounderNews() { 
	return( 
	<>  
    
    <section class="bg-gray section sub-bg">
      <div class="container">
        <div class="row">
            <div class="col-lg-8 mb-3">
              <a href="#">
                  <div class="row no-gutters zoomin h-100 aos-init aos-animate" data-aos="fade-left">
                    <div class="col-sm-6">
                        <div class="ceo-img h-100 ">
                          <img src="./assets/images/rajasekar.jpg"  class="img-fluid  h-100"/>     
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="bg-blue bg-blue-img h-100">
                              <h3 class="section-heading text-white">Founder Message </h3>
                              <p class="txt-lg text-white mb-4"><i class="fa fa-quote-left quote-left"></i>We aim to revolutionize the Indian startup ecosystem across various verticals, including social media and women's empowerment in the IT sector by 2030.<span><i class="fa fa-quote-right quote-right"></i></span></p>
                              <p class="ceo">Rajasekar Sundaresan<span>Founder & CEO, Pixalive Group</span></p>
                        </div>
                    </div>
                  </div>
              </a> 
          </div>
            <div class="col-lg-4 newsupdate mb-3">
              <div class="bg-white box-shadow p-4 h-100 aos-init aos-animate" data-aos="fade-right">
                  <h3 class="section-heading">News and Updates</h3>
                    <ul class="ul-news-section">
                        <li>
                          <img src="./assets/images/news-updates.svg" alt />
                          <div>
                              <a href="#" target="_blank">Pixalive has converted its application from monolithic to microservices.</a>
                              {/* <span>Oct 07, 2023  | Pixalive </span> */}
                          </div>                          
                        </li>
                        <li>
                          <img src="./assets/images/news-updates.svg" alt />
                          <div>
                              <a href="#" target="_blank">Pixalive secured dedicated office space with an employee count of over 30.</a>
                              {/* <span>Oct 05, 2023  | Pixalive </span> */}
                          </div>                          
                        </li>
                        <li>
                          <img src="./assets/images/news-updates.svg" alt />
                          <div>
                              <a href="#" target="_blank">Pixalive Group consists of six divisions aimed at fostering national growth</a>
                              {/* <span>Oct 04, 2023  | Pixalive </span> */}
                          </div>                          
                        </li> 
                         <li>
                          <img src="./assets/images/news-updates.svg" alt />
                          <div>
                              <a href="#" target="_blank">Pixalive emerged from the pursuit of its goals</a>
                              {/* <span>Oct 04, 2023  | Pixalive </span> */}
                          </div>                          
                        </li> 
                    </ul>           
                    {/* <a href="#" class="btn btn-link">Read more</a> */}
              </div>
            </div>                        
        </div>
      </div>
  </section>
             
  </> 
  ); 
  } 
  export default FounderNews;