
function HomeBusinesses() { 
	return( 
	<>  
    
   <section class="section what-we-do-wrap">
         <div class="container">                       
            <div class="section-header pb-lg-4 text-center">
               <h2 class="heading font-regular" data-aos="fade-up" data-aos-offset="100" data-aos-duration="650" data-aos-easing="ease-in-out">Businesses</h2>
               <div class="only-value-content-counter">
                  <p class="width-70" data-aos="fade-up" data-aos-offset="100" data-aos-duration="650" data-aos-easing="ease-in-out">
                  Pixalive businesses consist of both product-based and service-based offerings
                  </p>
               </div>
            </div>
         </div>
         <div class="our-business-verticals">
            <div class="container">
               <div class="row row-cols-lg-3 row-cols-1 g-3">
                  <div class="col" data-aos="fade-up" data-aos-delay="100">
                     <a href="/Businesses" class="wrap">
                        <div class="image">  
                              <img loading="lazy" src="./assets/images/bh-1.jpg" class="img-fluid" />  
                        </div>
                        <div class="title"> Pixalive Platforms 
                           <img loading="lazy" src="./assets/images/tilt-white-arrow.svg" />
                        </div> 
                     </a>
                  </div>
                  <div class="col" data-aos="fade-up" data-aos-delay="400">
                     <a href="/Businesses" class="wrap">
                        <div class="image">    
                           <img loading="lazy" src="./assets/images/bh-2.jpg" class="img-fluid" />                        
                        </div>  
                        <div class="title">Pixalive Works  <img loading="lazy" src="./assets/images/tilt-white-arrow.svg" /></div>
                     </a>
                  </div>
                  <div class="col" data-aos="fade-up" data-aos-delay="700">
                     <a href="/Businesses" class="wrap">
                        <div class="image">
                           <img loading="lazy" src="./assets/images/bh-3.jpg" class="img-fluid" />                            
                        </div>  
                        <div class="title">Pixalive Ventures  <img loading="lazy" src="./assets/images/tilt-white-arrow.svg" /></div>      
                     </a>
                  </div>
                  <div class="col" data-aos="fade-up" data-aos-delay="100">
                     <a href="/Businesses" class="wrap">
                        <div class="image">
                           <img loading="lazy" src="./assets/images/bh-4.jpg" class="img-fluid" />                            
                        </div>  
                        <div class="title"> Pixalive Services  <img loading="lazy" src="./assets/images/tilt-white-arrow.svg" /></div> 
                     </a>
                  </div>
                  <div class="col" data-aos="fade-up" data-aos-delay="400">
                     <a href="/Businesses" class="wrap">
                        <div class="image">
                           <img loading="lazy" src="./assets/images/bh-5.jpg" class="img-fluid" />                            
                        </div>  
                        <div class="title"> Pixalive Labs  <img loading="lazy" src="./assets/images/tilt-white-arrow.svg" /></div>
                     </a>
                  </div>
                  <div class="col" data-aos="fade-up" data-aos-delay="700">
                     <a href="/Businesses" class="wrap">
                        <div class="image">
                           <img loading="lazy" src="./assets/images/bh-6.jpg" class="img-fluid" />                            
                        </div>  
                        <div class="title">Pixalive Foundation  <img loading="lazy" src="./assets/images/tilt-white-arrow.svg" /></div>
                     </a>
                  </div> 
                  
               </div>
            </div>
            
         </div>
      </section>

  </> 
  ); 
  } 
  export default HomeBusinesses;