
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./pages/Home/Home";
import Banner from "./pages/Home/Banner";
import Services from "./pages/Home/Services";
import GroupWebsite from "./pages/Home/GroupWebsite";
import FounderNews from "./pages/Home/FounderNews";
import HomeBusinesses from "./pages/Home/HomeBusinesses";
import HomeSustainability from "./pages/Home/HomeSustainability";
import LatestNews from "./pages/Home/LatestNews";
import WorkWithUs from "./pages/Home/WorkWithUs";
import AboutUs from "./pages/OurCompany/AboutUs";
import Businesses from "./pages/Businesses/Businesses";
import Blogs from "./pages/Blogs/Blogs";
import ContactUs from "./pages/ContactUs/ContactUs";
import Investors from "./pages/Investors/Investors";
import Sustainability from "./pages/Sustainability/Sustainability";
import Careers from "./pages/Careers/Careers";
import JobDetail from "./pages/Careers/JobDetail";
import BlogDetail from "./pages/Blogs/BlogDetail"; 
import TheRiseofPixalive from "./pages/Blogs/TheRiseofPixalive";
import PixaliveBengaluruOffice from "./pages/Blogs/PixaliveBengaluruOffice";
import WhatAreTheMostPopularApps from "./pages/Blogs/WhatAreTheMostPopularApps";
import HeloAppAlternativesinIndiaLikePixalive from "./pages/Blogs/HeloAppAlternativesinIndiaLikePixalive";
import PixaliveappisNowtrendinginIndia from "./pages/Blogs/PixaliveappisNowtrendinginIndia";
import JobApply from "./pages/Careers/JobApply";
import Flutter from "./pages/Careers/Flutter";
import MernStack from "./pages/Careers/MernStack";
import MeanStack from "./pages/Careers/MeanStack";
import UXDesign from "./pages/Careers/UXDesign";
import BusinessDevelopment from "./pages/Careers/BusinessDevelopment";
import SoftwareTesting from "./pages/Careers/SoftwareTesting";
import HumanResources from "./pages/Careers/HumanResources";
import Academics from "./pages/Careers/Academics";
import CustomerSupport from "./pages/Careers/CustomerSupport";
import DevOpsEngineer from "./pages/Careers/DevOpsEngineer";


const routes = (
  <Router>    
    <Routes>
      <Route path='/' element={<Home/>} />
      <Route path='/Banner' element={<Banner/>} />
      <Route path='/Services' element={<Services/>} />
      <Route path='/FounderNews' element={<FounderNews/>} />
      <Route path='/HomeBusinesses' element={<HomeBusinesses/>} />
      <Route path='/HomeSustainability' element={<HomeSustainability/>} /> 
      <Route path='/LatestNews' element={<LatestNews/>} />
      <Route path='/WorkWithUs' element={<WorkWithUs/>} /> 
      <Route path='/GroupWebsite' element={<GroupWebsite/>} />

      <Route path='/AboutUs' element={<AboutUs/>} />
      <Route path='/Businesses' element={<Businesses/>} />
      <Route path='/Investors' element={<Investors/>} />
      <Route path='/Careers' element={<Careers/>} />
      <Route path='/JobDetail' element={<JobDetail/>} />
      <Route path='/Sustainability' element={<Sustainability/>} />      
      <Route path='/ContactUs' element={<ContactUs/>} />
      <Route path='/Blogs' element={<Blogs/>} />
      <Route path='/BlogDetail' element={<BlogDetail/>} />
      <Route path='/TheRiseofPixalive' element={<TheRiseofPixalive/>} />
      <Route path='/PixaliveBengaluruOffice' element={<PixaliveBengaluruOffice/>} />
      <Route path='/WhatAreTheMostPopularApps' element={<WhatAreTheMostPopularApps/>} />
      <Route path='/HeloAppAlternativesinIndiaLikePixalive' element={<HeloAppAlternativesinIndiaLikePixalive/>} />
      <Route path='/PixaliveappisNowtrendinginIndia' element={<PixaliveappisNowtrendinginIndia/>} />
      <Route path='/JobApply' element={<JobApply/>} />
      <Route path='/Flutter' element={<Flutter/>} />
      <Route path='/MernStack' element={<MernStack/>} />
      <Route path='/MeanStack' element={<MeanStack/>} />
      <Route path='/UXDesign' element={<UXDesign/>} />
      <Route path='/BusinessDevelopment' element={<BusinessDevelopment/>} />
      <Route path='/SoftwareTesting' element={<SoftwareTesting/>} />
      <Route path='/HumanResources' element={<HumanResources/>} />
      <Route path='/Academics' element={<Academics/>} />
      <Route path='/CustomerSupport' element={<CustomerSupport/>} />
      <Route path='/DevOpsEngineer' element={<DevOpsEngineer/>} />
      
      
      
      
      
    </Routes>
 </Router> )
function App() {
  return (
    <>
        {routes}  
    </>
  );
}

export default App;
