import Header from '../../components/Header';  
import Footer from '../../components/Footer';
function TheRiseofPixalive() { 
	return( 
	<>  
    
      <Header /> 
      
      <div class="breadcrumb-area bg-gray bg-cover">
        <div class="container">
            <div class="breadcrumb-item">
                <div class="breadcrum-shape">
                    <img src="./assets/images/bg-shape-3.png" alt="shape" />
                </div>
                <div class="row">
                    <div class="col-lg-8">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li><a href="/Blogs"><i class="fa fa-home"></i>Blog</a></li>
                                <li class="active">Blog Detail</li>
                            </ol>
                        </nav>
                        <h1>Blog Detail</h1>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div class="paragraph">
         <div class="container">
            <div class="row articles-lower ">
               <div class="col-md-8">
                     <div class="article_title">
                        <div class="editorial">
                           <a href="#">Social Media</a>
                        </div>
                        <h2 class="node__title">
                           <span>The Rise of Pixalive: Pioneering a New Era in India’s Social Media Landscape</span>
                        </h2>
                        <div class="short-desc">
                        Upon hearing the controversial statement made by the CEO of Snapchat, who stated, “This app is only for rich people. I do not want to expand into poor countries like India and Spain,” Rajasekar was inspired to act.
                        </div>
                     </div>
                     <div class="articles-upper">
                        <div class="authors authors-box"> 
                           <div class="blogger-inforow">
                              <div class="blogger-pic">
                                 <img src="../assets/images/team/vignesh.jpg" alt="Jaydeep Saha " title="Jaydeep Saha "/>
                              </div>
                              <div class="blogger-details">
                                 <div class="blogger-name">Vignesh Dhanasekaran </div>
                                 <div class="blogger-designation">COO, Pixalive Group </div>
                              </div>
                           </div>
                           <div class="article-details">
                              <div class="date desktop">
                                 <div class="field field--name-field-page-publish-date field--type-datetime field--label-hidden field__item">
                                    <time datetime="2023-08-29T12:00:00Z" class="datetime">August 29, 2023</time>
                                 </div>
                              </div>
                              <div class="read-time desktop">7 minutes</div>
                              <div class="socila-media">
                                 <span>Share</span>
                                 <ul>
                                    <li>
                                    <a href="#" aria-label="facebook" target="blank" >
                                       <img class="img-fluid" src="./assets/images/facebook.svg" /> 
                                    </a>
                                    </li>
                                    <li>
                                    <a href="#" aria-label="twitter" target="blank">
                                       <img class="img-fluid" src="./assets/images/twitter.svg" />
                                    </a>
                                    </li>

                                    <li>
                                    <a href="#" aria-label="linkedin" target="blank">
                                       <img class="img-fluid" src="./assets/images/linkedin.svg" />
                                    </a>
                                    </li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                        <div class="article-image article-image-app"> 
                           <img src="https://miro.medium.com/v2/resize:fit:384/format:webp/1*t3RgIrSKGlzHkmNhiNE9hg.png" width="700" height="357" alt=""/>
                        </div>
                        <div class="article-image-app-content">
                           <span>Pixalive Google Play Store link:<a href="https://apps.apple.com/in/app/pixalive/id1491966281">https://apps.apple.com/in/app/pixalive/id1491966281</a></span>
                           <span>Pixalive App Store link:<a href="https://apps.apple.com/in/app/pixalive/id1491966281">https://apps.apple.com/in/app/pixalive/id1491966281</a></span> 
                           <span>Website:<a href="https://pixalive.me/">https://pixalive.me/</a></span> 
                        </div>                        
                     </div>
                     <div class="articles-lower">
                        <div class="article-body">
                           <div class="field--name-body">
                              <h6>Founder History</h6>
                              <p>Mr. Rajasekar Sundaresan, a remarkable individual from a middle-class family and the son of a farmer, successfully pursued his Bachelor’s degree in Electronics and Communication Engineering (BE ECE) from Sapthagiri College of Engineering from 2008–2012. However, his educational journey faced a significant hurdle due to financial constraints. It was during this challenging time that Dr. Abdul Kalam, a visionary leader, and renowned figure, stepped in to provide crucial support.</p>
                              <p class="mk">Dr. Abdul Kalam’s generous funding became the turning point in Rajasekar Sundaresan’s life, allowing him to overcome the financial obstacles and continue his education. The impact of Dr. Abdul Kalam’s support cannot be overstated, as it played an instrumental role in shaping Rajasekar Sundaresan’s path and propelling him toward his current achievements.</p>
                              <div class="blog-inner-image">
                                 <div>
                                    <img src="../assets/images/rajasekar.webp" alt=""/>
                                    <span>Mr. Rajasekar Sundaresan, Founder & CEO, Pixalive</span>
                                 </div>
                                 <div>
                                    <p>Without the initial financial assistance provided by Dr. Abdul Kalam, Rajasekar Sundaresan’s dreams and aspirations might have remained unfulfilled. </p>
                                    <p>The opportunities and accomplishments he has attained today stand as a testament to the transformative power of education and the profound impact that acts of support and generosity can have on an individual’s life.</p>
                                    <p>Rajasekar Sundaresan’s journey serves as an inspiring example of resilience, determination, and the positive influence that influential figures like Dr. Abdul Kalam can have in shaping the future of deserving individuals. </p>
                                    <p>His story underscores the importance of providing support and opportunities to those facing adversities, ultimately leading to the betterment of society as a whole.</p>
                                 </div>
                              </div>                        
                              <h6>Company History</h6>                        
                              <p class="mk">Upon hearing the controversial statement made by the CEO of Snapchat, who stated, “This app is only for rich people. I do not want to expand into poor countries like India and Spain,” Rajasekar was inspired to act. He displayed great courage by resigning from his prestigious position at the top IT firm, WIPRO, and embarked on a mission to create a <a class="lc">“Make in India”</a> Social Media application. In 2018, his vision became a reality with the launch of Pixalive, developed under the parent company <a class="lc">Tripalive.Me Technologies Private Limited.</a> This achievement marked a significant milestone in India’s social media landscape.</p>
                              <p>Rajasekar journeyed to Delhi to begin the development of the application from scratch. He invested all his savings and even pledged his property to raise 30 lakhs, which he used to advance the project. With unwavering determination, he successfully developed the <a class="lc">Pixalive application</a> in August 2018 and launched it on the <a class="lc">Google Play Store</a>. Throughout this phase, Rajasekar received support from his family members & friend Mr. Prabhakaran, they played a pivotal role in helping him achieve his ambitious goal. Initially, it was challenging to bring in the first 1000 users.</p> 
                              <div class="center-image">
                                 <img src="../assets/images/raja-prbha.webp" alt=""/>
                                 <span>Mr. Rajasekar & his friend Mr. Prabhakaran</span>
                              </div>
                              <h6>Golden Period</h6>
                              <p>Pixalive conducted an event in December 2018 with Ms. Disha Ghosh as the brand ambassador. Within 3 months from that event, we receive more than 1 Lakh traction. However, after that phase, we gained significant traction and popularity among users.</p>
                              <div class="center-image">
                                 <img src="../assets/images/mrs-anitha.webp" alt=""/>
                                 <span>Miss. Disha Ghosh, Indian Volleyball player & Mrs. Anitha Sampath, Actress</span>
                              </div>
                              <p>In February 2019, Mr. Rajasekar met <a class="lc">Mr. Bharath Kumar Jain and Mr. Abhishek Jain </a> for the first time through a mutual connection. They agreed to become legal and compliance advisors for Pixalive. Subsequently, they assisted us in securing seed funding from investors and joined the Pixalive Board Members. <a class="lc">Mr. Bharath Kumar Jain</a> played a crucial role as a <strong>“Stalwart of Pixalive”</strong> in making legal and logical decisions.</p>
                              <p class="mk">Our rapid growth caught the attention of investors, who displayed faith in our company by investing approximately 0.5 million USD in July 2019. This boosted Pixalive’s morale and reinforced our belief that we were moving in the right direction to achieve our goals. In August 2019, we organized another event where we appointed Mrs. Pranitha Subhash as the second brand ambassador. This event led to a steady increase in Pixalive’s user base and downloads. In December 2019, we successfully introduced the Pixalive iOS version in the App Store.</p>
                              <div class="center-image">
                                 <img src="../assets/images/mrs-pranitha.webp" alt=""/>
                                 <span>Mrs. Pranitha Subhash, Actress & Mr. Bharath Kumar Jain, Director K2D Multidisciplinary Services</span>
                              </div>
                              <p>During this period, Pixalive gradually established its core technical team, with the technical operations based in Bengaluru and the marketing operations based in Chennai. Additionally, Pixalive expanded its presence to two metro cities: Chennai and Bengaluru.</p>
                              <div class="center-image">
                                 <img src="../assets/images/nithesh.webp" alt=""/>
                                 <span>Mr. Nitesh Jain, Mr. Bharath Kumar Jain & Mr. Abhishek Jain</span>
                              </div>
                              <p>Everything was running smoothly with a team capacity of 25 members until March 2020. During this time, Pixalive aimed to reach a user base of 1 million, setting the stage for potential series A funding.</p>
                              <p class="mk">“EVERY DAY CREATE YOUR HISTORY; EVERY PATH YOU TAKE YOU’RE LEAVING YOUR LEGACY”</p>
                              <h6>Challenging Times</h6>
                              <p>In March 2020, our dream faced a major obstacle — COVID-19. The pandemic disrupted everyone’s plans, leading to a global curfew situation, an unprecedented event in history. Pixalive was also affected, unable to carry out planned activities and promotions. Despite aggressive digital marketing, which resulted in increased downloads, our application experienced performance issues, running slower and encountering lag.</p>
                              <p>Our employees continued to work from home, but we encountered scaling issues due to the high number of active users and installations resulting from previous work and promotions. This caused our app to run slower, posing a challenging task to resolve. Coordinating with our remote employees became difficult, impacting our productivity.</p>
                              <p class="mk">During that period, our employees diligently made efforts to resolve the scaling issue but were unable to find a solution due to their limited experience in the field. The team consisted primarily of entry-level developers led by a few mid-level developers, and their expertise was not yet sufficient to effectively address and resolve the problem. As a result, this extended resolution period significantly impacted our user engagement rate.</p>
                              <p>Due to COVID-19, Pixalive extended the annual appraisal period of the organization to prioritize recovery and growth. However, some employees failed to understand the company’s situation and lacked gratitude. They began searching for jobs elsewhere resulting in our employee count decreasing from 25 to 10. Additionally, they failed to share knowledge with the newly onboarded employees.</p>
                              <p class="mk">As a startup, we initially built our application as a monolithic one, but as we reached a higher user base, several issues emerged:</p>
                              <p><strong class="ew">1. Slower development speed</strong> A large, monolithic application makes development more complex and slower.</p>
                              <p><strong class="ew">2. Scalability </strong>Cannot scale the individual components. </p>
                              <p><strong class="ew">3. Reliability </strong>If an error occurred in any module, it could affect the entire application’s availability, affecting user engagement. </p>
                               
                              <p>In response, we decided to revamp our application from scratch and adopt an interest-based theme. The application now operates based on users’ categories of interest, ensuring only relevant content is displayed. This approach allows users to build communities and make the most of their time spent on the platform.</p>
                              <p>In December 2021, Mr. Rajasekar got married, cherishing the sweet memories from that period. His understanding wife has been a constant moral supporter, encouraging him to take Pixalive forward in all possible ways.</p>
                              <p><strong class="ew">“GOD GIVES HIS TOUGHEST BATTLE TO HIS STRONGEST SOLDIERS”</strong></p>
                              <h6>Redemption Phase</h6>
                              <p>In January 2022, Mr. Rajasekar met Mr. Vignesh Dhanasekaran through a closed network. It was their first meeting, and they immediately discovered that they shared common ideas, vision, and goals to establish a successful business. After several months, in October 2022, they began traveling together to enhance Pixalive and brainstormed various revenue-earning ideas.</p>
                              <div class="center-image">
                                 <img src="../assets/images/vignesh.webp" alt=""/>
                                 <span>Mr. Vignesh Dhanasekaran, COO</span>
                              </div>
                              <p class="mk">In January 2023, Mr. Vignesh proposed the creation of Pixalive Labs, a revenue-generating division focused on people upskilling through a corporate training program. As a social media company, Pixalive had the advantage of gaining trust, achieving broad coverage, and attracting a large number of candidates for enrolment. Recognizing Mr. Vignesh’s expertise in decision-making and operational strategy, Mr. Rajasekar appointed him as the Chief Operating Officer (COO) of Pixalive in February 2023. With his valuable input, Pixalive consistently made the right decisions, avoiding any mistakes. Their shared vision, ideas, and implementation strategies aligned perfectly. Mr. Vignesh Dhanasekaran provided unwavering moral support to Mr. Rajasekar and Pixalive, standing tall by their side.</p>
                              <p>Currently, Pixalive has a team of 20+ employees working both in-house and remotely. The application is managed and led by experienced senior developers in the field under the guidance of Mr. Sathish Kumar.</p>
                              <div class="three-center-image">
                                 <div class="tc-img">
                                    <figure class="nn paragraph-image">                                    
                                       <img src="../assets/images/pix1.webp" alt=""/>                                 
                                    </figure>
                                    <figure class="ns paragraph-image">                                    
                                       <img src="../assets/images/pix2.webp" alt=""/>                                 
                                    </figure>
                                    <figure class="nt paragraph-image">                                    
                                       <img src="../assets/images/pix3.webp" alt=""/>                                 
                                    </figure>
                                    </div>
                                 <div>
                                    <p>Pixalive Team</p>
                                 </div>
                              </div>
                              <p>On June 30th, 2023, which coincided with World Social Media Day, Pixalive accomplished a successful relaunch of its application. During this relaunch, the company placed a primary focus on emphasizing its vision and refreshing its logo. The objective behind this effort was to provide users with a more user-friendly and distinctive experience, promising enhanced clarity and an overall improved user experience. The relaunch marked a significant milestone for Pixalive as it aimed to align its platform with the latest industry standards and meet the evolving needs of its user base.</p>
                              <div class="center-image">
                                 <img src="../assets/images/pix-app.webp" alt=""/>
                                 <span>Pixalive — Made in India</span>
                              </div>
                              <p>Now Pixalive is actively working towards implementing microservices and is planning to launch it in the market soon. Scalability improvements, Improved fault isolation, Simpler deployment process, Easy Integration, Faster time-to-market, Ability to experiment, Improved data security are some of the benefits of microservices.</p>
                              <p><strong class="ew">“WE ARE NOT AFRAID TO START OVER. THIS TIME WE ARE NOT STARTING FROM SCRATCH, WE ARE STARTING FROM EXPERIENCE.”</strong></p>
                           </div>
                        </div>
                        
                        <div class="article-body mobile-show">
                           <div class="articles-tags">TAGS: <div class="field field--name-field-free-tags field--type-entity-reference field--label-hidden field__items">
                              <div class="field__item">Social Media</div>
                              <div class="field__item">Trending</div>
                              <div class="field__item">Hashtag</div> 
                              <div class="field__item">Startup</div>
                              <div class="field__item">Fundraising</div>
                              <div class="field__item">Investing</div>
                              <div class="field__item">Technology</div>
                           </div>
                           </div>
                           <div class="socila-media">
                           <span>Share On</span>
                           <ul>
                              <li>
                                 <a href="#" aria-label="facebook" target="blank" >
                                    <img class="img-fluid" src="./assets/images/facebook.svg" /> 
                                 </a>
                              </li>
                              <li>
                                 <a href="#" aria-label="twitter" target="blank">
                                    <img class="img-fluid" src="./assets/images/twitter.svg" />
                                 </a>
                              </li>
                              <li>
                                 <a href="#" aria-label="linkedin" target="blank">
                                    <img class="img-fluid" src="./assets/images/linkedin.svg" />
                                 </a>
                              </li>
                           </ul>
                           <div class="copy-link">
                              <span>Copy link</span>
                              <a id="copy_link" href="javascript:;" cururl="#">
                                 <img src="../assets/images/copy-link.png" alt="copy-link" width="100" height="100"/>
                              </a> 
                           </div>
                        </div>
                     </div>
                  </div>                        
               </div>

               <div class="col-md-4 cta-forms">
                  <div class="inner-cta-forms-trends mobile-f-w"> 
                     <div class="right-banner">
                        <div class="field field--name-field-sidebar-ad field--type-entity-reference field--label-hidden field__item">
                           <div class="eck-entity">
                           <div class="right-banner"> 
                                 <img src="../assets/images/ads.jpg" title=""/>
                              </div>
                              <div class="content">
                                 <h4 class="base-color-white brand-no-case">Golden Period</h4>
                                 <p>Pixalive inaugurated its new office space in Bengaluru on August 28, 2023, marking the beginning of a golden era for the company. </p>
                                 <p>Within just one month, on September 27, 2023, it secured a dedicated office space and already employed more than 30 individuals.</p>
                                 <p>It has expanded into five new divisions apart from its Social Media division, including Startup Support, SaaS, IT Services, Software Training, and Sustainability.</p>
                                 <a class="arrow-btn-white" href="/PixaliveBengaluruOffice">View More</a>                              
                              </div> 
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

            </div>
         </div> 
      </div>
      
      <Footer/>

  </> 
  ); 
  } 
  export default TheRiseofPixalive;