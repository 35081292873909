
function Banner() { 
	return( 
	<>
    
    
    <section class="section spotlight-home-wrap m-0 p-0">
        <div class="beam-slider main-slider">
            <div class="swiper-wrapper">
                <div class="swiper-slide">
                    <div class="slide-bgimg">                                
                        <img src="./assets/images/banner-3.jpg" alt />
                    </div>
                    <div class="mob-content-home-spotlight">
                        <div class="content text-center text-lg-start">
                            <svg  viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.922 4.5V11.8125C13.922 11.9244 13.8776 12.0317 13.7985 12.1108C13.7193 12.1899 13.612 12.2344 13.5002 12.2344C13.3883 12.2344 13.281 12.1899 13.2018 12.1108C13.1227 12.0317 13.0783 11.9244 13.0783 11.8125V5.51953L4.79547 13.7953C4.71715 13.8736 4.61092 13.9176 4.50015 13.9176C4.38939 13.9176 4.28316 13.8736 4.20484 13.7953C4.12652 13.717 4.08252 13.6108 4.08252 13.5C4.08252 13.3892 4.12652 13.283 4.20484 13.2047L12.4806 4.92188H6.18765C6.07577 4.92188 5.96846 4.87743 5.88934 4.79831C5.81023 4.71919 5.76578 4.61189 5.76578 4.5C5.76578 4.38811 5.81023 4.28081 5.88934 4.20169C5.96846 4.12257 6.07577 4.07813 6.18765 4.07812H13.5002C13.612 4.07813 13.7193 4.12257 13.7985 4.20169C13.8776 4.28081 13.922 4.38811 13.922 4.5Z" fill="currentColor"></path>
                            </svg>
                            <span class="banner-business-title">Pixalive Platforms</span>
                            <h2 class="heading font-medium font-size-tb">Connecting Minds, Empowering Businesses: Your Privacy-Centric Network</h2>
                            <p>Our platform serves as a networking tool and a privacy-centric solution for connecting like-minded people & promoting businesses</p>
                            <a href="/Businesses" class="btn btn-primary">View More</a>
                            <div class="numPagination">
                                <span class="slideNumber">1</span> &nbsp; /&nbsp;
                                <span class="totalSlide">6</span>
                            </div>
                        </div> 
                    </div>
                </div>
                <div class="swiper-slide">
                    <div class="slide-bgimg ">
                        <picture>                                    
                        <img src="./assets/images/banner-4.png" alt />                                    
                        </picture>
                    </div>
                    <div class="mob-content-home-spotlight">
                        <div class="content text-center text-lg-start">
                            <svg  viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.922 4.5V11.8125C13.922 11.9244 13.8776 12.0317 13.7985 12.1108C13.7193 12.1899 13.612 12.2344 13.5002 12.2344C13.3883 12.2344 13.281 12.1899 13.2018 12.1108C13.1227 12.0317 13.0783 11.9244 13.0783 11.8125V5.51953L4.79547 13.7953C4.71715 13.8736 4.61092 13.9176 4.50015 13.9176C4.38939 13.9176 4.28316 13.8736 4.20484 13.7953C4.12652 13.717 4.08252 13.6108 4.08252 13.5C4.08252 13.3892 4.12652 13.283 4.20484 13.2047L12.4806 4.92188H6.18765C6.07577 4.92188 5.96846 4.87743 5.88934 4.79831C5.81023 4.71919 5.76578 4.61189 5.76578 4.5C5.76578 4.38811 5.81023 4.28081 5.88934 4.20169C5.96846 4.12257 6.07577 4.07813 6.18765 4.07812H13.5002C13.612 4.07813 13.7193 4.12257 13.7985 4.20169C13.8776 4.28081 13.922 4.38811 13.922 4.5Z" fill="currentColor"></path>
                            </svg>
                            <span class="banner-business-title">Pixalive Works</span>
                            <h2 class="heading font-medium font-size-tb">Seamless Solutions, Limitless Possibilities: Your SaaS Partner</h2>
                            <p>At Pixalive Talents, we understand the importance of technology in transforming ideas into successful products</p>
                            <a href="/Businesses" class="btn btn-primary">View More</a>
                            <div class="numPagination">
                                <span class="slideNumber">2</span> &nbsp; /&nbsp;
                                <span class="totalSlide">6</span>
                            </div>
                        </div>
                    </div>                    
                </div>

                <div class="swiper-slide">
                    <div class="slide-bgimg">                                 
                        <img src="./assets/images/banner-3.jpg" alt />
                    </div>
                    <div class="mob-content-home-spotlight">
                    <div class="content text-center text-lg-start">
                        <svg  viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.922 4.5V11.8125C13.922 11.9244 13.8776 12.0317 13.7985 12.1108C13.7193 12.1899 13.612 12.2344 13.5002 12.2344C13.3883 12.2344 13.281 12.1899 13.2018 12.1108C13.1227 12.0317 13.0783 11.9244 13.0783 11.8125V5.51953L4.79547 13.7953C4.71715 13.8736 4.61092 13.9176 4.50015 13.9176C4.38939 13.9176 4.28316 13.8736 4.20484 13.7953C4.12652 13.717 4.08252 13.6108 4.08252 13.5C4.08252 13.3892 4.12652 13.283 4.20484 13.2047L12.4806 4.92188H6.18765C6.07577 4.92188 5.96846 4.87743 5.88934 4.79831C5.81023 4.71919 5.76578 4.61189 5.76578 4.5C5.76578 4.38811 5.81023 4.28081 5.88934 4.20169C5.96846 4.12257 6.07577 4.07813 6.18765 4.07812H13.5002C13.612 4.07813 13.7193 4.12257 13.7985 4.20169C13.8776 4.28081 13.922 4.38811 13.922 4.5Z" fill="currentColor"></path>
                        </svg>
                        <span class="banner-business-title">Pixalive Ventures</span>
                        <h2 class="heading font-medium font-size-tb">From Ideas to Success: Your All-in-One Support Partner</h2>
                        <p>We offer comprehensive support, spanning ideation, technical assistance, digital marketing, legal compliance, and financial guidance</p>
                        <a href="/Businesses" class="btn btn-primary">View More</a>
                        <div class="numPagination">
                            <span class="slideNumber">3</span> &nbsp; /&nbsp;
                            <span class="totalSlide">6</span>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="swiper-slide">
                    <div class="slide-bgimg ">
                        <picture>                                         
                            <img src="./assets/images/banner-3.jpg" alt />                                
                        </picture>
                    </div>
                    <div class="mob-content-home-spotlight">
                        <div class="content text-center text-lg-start">
                            <svg  viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.922 4.5V11.8125C13.922 11.9244 13.8776 12.0317 13.7985 12.1108C13.7193 12.1899 13.612 12.2344 13.5002 12.2344C13.3883 12.2344 13.281 12.1899 13.2018 12.1108C13.1227 12.0317 13.0783 11.9244 13.0783 11.8125V5.51953L4.79547 13.7953C4.71715 13.8736 4.61092 13.9176 4.50015 13.9176C4.38939 13.9176 4.28316 13.8736 4.20484 13.7953C4.12652 13.717 4.08252 13.6108 4.08252 13.5C4.08252 13.3892 4.12652 13.283 4.20484 13.2047L12.4806 4.92188H6.18765C6.07577 4.92188 5.96846 4.87743 5.88934 4.79831C5.81023 4.71919 5.76578 4.61189 5.76578 4.5C5.76578 4.38811 5.81023 4.28081 5.88934 4.20169C5.96846 4.12257 6.07577 4.07813 6.18765 4.07812H13.5002C13.612 4.07813 13.7193 4.12257 13.7985 4.20169C13.8776 4.28081 13.922 4.38811 13.922 4.5Z" fill="currentColor"></path>
                            </svg>
                            <span class="banner-business-title">Pixalive Consultancy Services</span>
                            <h2 class="heading font-medium font-size-tb">Unlocking IT Excellence, One Platform, Many Solutions</h2>
                            <p>We excel in offering a comprehensive range of IT services, digital services, consulting, business solutions, project management, and talent outsourcing all within a single platform</p>
                            <a href="/Businesses" class="btn btn-primary">View More</a>
                            <div class="numPagination">
                                <span class="slideNumber">4</span> &nbsp; /&nbsp;
                                <span class="totalSlide">6</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="swiper-slide">
                    <div class="slide-bgimg">                                
                        <img src="./assets/images/banner-3.jpg" alt />
                    </div>
                    <div class="mob-content-home-spotlight">
                        <div class="content text-center text-lg-start">
                            <svg  viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.922 4.5V11.8125C13.922 11.9244 13.8776 12.0317 13.7985 12.1108C13.7193 12.1899 13.612 12.2344 13.5002 12.2344C13.3883 12.2344 13.281 12.1899 13.2018 12.1108C13.1227 12.0317 13.0783 11.9244 13.0783 11.8125V5.51953L4.79547 13.7953C4.71715 13.8736 4.61092 13.9176 4.50015 13.9176C4.38939 13.9176 4.28316 13.8736 4.20484 13.7953C4.12652 13.717 4.08252 13.6108 4.08252 13.5C4.08252 13.3892 4.12652 13.283 4.20484 13.2047L12.4806 4.92188H6.18765C6.07577 4.92188 5.96846 4.87743 5.88934 4.79831C5.81023 4.71919 5.76578 4.61189 5.76578 4.5C5.76578 4.38811 5.81023 4.28081 5.88934 4.20169C5.96846 4.12257 6.07577 4.07813 6.18765 4.07812H13.5002C13.612 4.07813 13.7193 4.12257 13.7985 4.20169C13.8776 4.28081 13.922 4.38811 13.922 4.5Z" fill="currentColor"></path>
                            </svg>
                            <span class="banner-business-title">Pixalive Labs</span>
                            <h2 class="heading font-medium font-size-tb">Empowering Innovation, Fueling Success: Pixalive Labs for Tomorrow's Workforce</h2>
                            <p>We focus on corporate training and skill development, providing a range of technology programs that help people get better at their jobs and prepare for shifts in the business world</p>
                            <a href="/Businesses" class="btn btn-primary">View More</a>
                            <div class="numPagination">
                                <span class="slideNumber">5</span> &nbsp; /&nbsp;
                                <span class="totalSlide">6</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="swiper-slide">
                    <div class="slide-bgimg ">
                        <picture>                                         
                        <img src="./assets/images/banner-3.jpg" alt />                                  
                        </picture>
                    </div>
                    <div class="mob-content-home-spotlight">
                        <div class="content text-center text-lg-start">
                            <svg  viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.922 4.5V11.8125C13.922 11.9244 13.8776 12.0317 13.7985 12.1108C13.7193 12.1899 13.612 12.2344 13.5002 12.2344C13.3883 12.2344 13.281 12.1899 13.2018 12.1108C13.1227 12.0317 13.0783 11.9244 13.0783 11.8125V5.51953L4.79547 13.7953C4.71715 13.8736 4.61092 13.9176 4.50015 13.9176C4.38939 13.9176 4.28316 13.8736 4.20484 13.7953C4.12652 13.717 4.08252 13.6108 4.08252 13.5C4.08252 13.3892 4.12652 13.283 4.20484 13.2047L12.4806 4.92188H6.18765C6.07577 4.92188 5.96846 4.87743 5.88934 4.79831C5.81023 4.71919 5.76578 4.61189 5.76578 4.5C5.76578 4.38811 5.81023 4.28081 5.88934 4.20169C5.96846 4.12257 6.07577 4.07813 6.18765 4.07812H13.5002C13.612 4.07813 13.7193 4.12257 13.7985 4.20169C13.8776 4.28081 13.922 4.38811 13.922 4.5Z" fill="currentColor"></path>
                            </svg>
                            <span class="banner-business-title">Pixalive Foundation</span>
                            <h2 class="heading font-medium font-size-tb">Enriching Lives, Nurturing the Future: Sustainable Impact with Pixalive</h2>
                            <p>Pixalive Foundation is the Corporate Social Responsibility (CSR) activity division of Pixalive Group. Focuses sustainability in Environment & Education.</p>
                            <a href="/Businesses" class="btn btn-primary">View More</a>
                            <div class="numPagination">
                                <span class="slideNumber">6</span> &nbsp; /&nbsp;
                                <span class="totalSlide">6</span>
                            </div>
                        </div>
                    </div>
                </div>
            
            </div>
            <div class="swiper-button-prev slide-home-btn swiper-button-white"></div>
            <div class="swiper-button-next slide-home-btn swiper-button-white"></div>
            <div class="swiper-pagination bullet-bottom"></div>
            
        </div>
            
    </section>
    
        
  </> 
  ); 
  } 
  export default Banner;