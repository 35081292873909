import Header from '../../components/Header';  
import Footer from '../../components/Footer';
function SoftwareTesting() { 
	return( 
	<>  
    
      <Header /> 
      <div class="breadcrumb-area bg-gray bg-cover">
        <div class="container">
            <div class="breadcrumb-item">
                <div class="breadcrum-shape">
                    <img src="./assets/images/bg-shape-3.png" alt="shape" />
                </div>
                <div class="row">
                    <div class="col-lg-8">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li><a href="/Careers"><i class="fa fa-home"></i>Careers</a></li>
                                <li class="active">Job Description</li>
                            </ol>
                        </nav>
                        <h1>Job Description</h1>
                    </div>
                </div>
            </div>
        </div>
      </div>

      <article role="article" class="node node--type-kenexa-jobs node--view-mode-full clearfix" id="training-hiring-programs" >
        <div class="section-jobs-page">
            <div class="container">
            {/* <div class="page-title">
                <h2>Job Description</h2>
            </div> */}
            <div class="node__content clearfix">
                <h3 class="node__title jobs-title custom-text-uppercase">
                <span class="field field--name-title field--type-string field--label-hidden">Software Testing </span>
                </h3>
                <div class="main-content-body">
                <div class="left-info">
                    <h4 class="job-summary">Job Summary</h4>
                    <div class="row">
                    <div class="col-md-4 kenexa-inline-field jobs-location">
                        <div class="kenexa-inline-box"> 
                        <div class="kenexa-field-text">
                            <h6 class="custom-text-uppercase">Location:</h6>
                            <p> Bangalore </p>
                        </div>
                        </div>
                    </div>
                    <div class="col-md-4 kenexa-inline-field jobs-designation">
                        <div class="kenexa-inline-box"> 
                        <div class="kenexa-field-text">
                            <h6 class="custom-text-uppercase">Designation:</h6>
                            <p>Software Tester</p>
                        </div>
                        </div>
                    </div>
                    <div class="col-md-4 kenexa-inline-field jobs-qualification">
                        <div class="kenexa-inline-box"> 
                        <div class="kenexa-field-text">
                            <div class="qualification">
                            <h6 class="custom-text-uppercase">Qualification:</h6>
                            </div>
                            <p>B.E, B.Tech </p>
                        </div>
                        </div>
                    </div>
                    <div class="col-md-4 kenexa-inline-field jobs-experience">
                        <div class="kenexa-inline-box"> 
                        <div class="kenexa-field-text">
                            <h6 class="custom-text-uppercase">Experience:</h6>
                            <p>2.5-3 Years </p>
                        </div>
                        </div>
                    </div>
                    <div class="col-md-4 kenexa-inline-field jobs-skills">
                        <div class="kenexa-inline-box"> 
                        <div class="kenexa-field-text">
                            <div class="skills">
                            <h6 class="custom-text-uppercase">Skills:</h6>
                            </div>
                            <p>Manual & Automated Testing</p>
                        </div>
                        </div>
                    </div>
                    <div class="col-md-4 kenexa-inline-field jobs-positions">
                        <div class="kenexa-inline-box"> 
                        <div class="kenexa-field-text">
                            <h6 class="custom-text-uppercase">No. of positions:</h6>
                            <p> 03 </p>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 jobs-description">
                    <div class="kenexa-inline-box d-block">
                        <div class="kenexa-inline-box-job d-flex"> 
                        <h6 class="custom-text-uppercase">Job description:</h6>
                        </div>
                        <div class="kenexa-field-text">
                        <div class="jobs-description">
                            <div class="description">
                            {/* <p> Role & responsibilities</p> */}
                            <ul>
                                <li>Reviewing software requirements and preparing test scenarios.</li>
                                <li>Executing tests on software usability.</li>
                                <li>Analyzing test results on database impacts, errors or bugs, and usability.</li>
                                <li>Preparing reports on all aspects related to the software testing carried out and reporting to the design team.</li>
                                <li>Interacting with clients to understand product requirements.</li>
                                <li>Participating in design reviews and providing input on requirements, product design, and potential problems.</li>
                                <li>Up-to-date knowledge of software test design and testing methodologies.</li>
                                <li>Working knowledge of test techniques and compatibility with various software programs.</li>
                                <li>Good communication skills to convey technical concepts effectively.</li>


                            </ul>
                            <p></p>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div class="col-lg-12">
                    <div class="jobs-link mt-3">
                        <a class="long-button blue" href="/JobApply" rel="noopener">Apply</a>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </article>
      
      <Footer/>

  </> 
  ); 
  } 
  export default SoftwareTesting;